import { Skeleton } from '@/components/Skeleton';

import i18n from '@/i18n';
import { Container } from '@mui/joy';

import { GenericList } from '@/components/GenericList';
import { useManager } from '@/hooks';

export function LabelsPage() {
  return (
    <LabelsView />
  )
}

export function LabelsSkeleton() {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
      </div>
    </Container>
  )
}

export function LabelsView() {
  const manager = useManager()

  return (
    <Container>
      <GenericList
        heading={i18n.t('labels')}
        addUrl={`../label/add`}
        addLabel={i18n.t('add-label')}
        getNodeHref={(label) => `/label/${label.slug}`}
        emptyText={`No labels found`}
        filterPlaceholder={`filter-labels`}
        path={`/labels`}
        baseUrl={`/${manager?.type}/${manager?.slug}/label`}
      />
    </Container>
  ) 
}

export default LabelsPage
