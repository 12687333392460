import React, { useState } from 'react';
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { RecordingForm } from './[recordingId]/edit';
import { uploadAudioToRecording } from '@/actions/login';
import { enqueueSnackbar } from 'notistack';

import i18n from '@/i18n';
import { Container } from '@mui/joy';
import { FeatureCard } from '@/components/FeatureCard';
import { Link } from 'react-router-dom';
import { CardsThree } from '@phosphor-icons/react';
import { useManager } from '@/hooks';
import { createRecording } from '@/actions/aloader';

import { useMutation } from '@tanstack/react-query';

export default function AddRecordingPage() {
  const { recordingId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddRecordingView recordingId={recordingId} />
    </React.Suspense>
  )
}

export function AddRecordingView() {
  const navigate = useNavigate()

  const manager = useManager()

  const [saving, setSaving] = useState(false)

  const addRecording = async (values) => {
    const recording = await createRecording(
      manager.type,
      manager.slug,
      {
      ...values,
      manager: manager.id
    }) 
    if (values.audio) {
      await uploadAudioToRecording(recording.id, values.audio)
      enqueueSnackbar(i18n.t('track-saved'))
    } else {
    } 
    return recording
  }

  const mutation = useMutation({
    mutationFn: addRecording,
    onMutate: (values) => {
      setSaving(true)
    },
    onSettled: (data) => {
      setSaving(false)
      enqueueSnackbar(i18n.t('track-saved'))
      navigate(`../../recording/${data.id}`)
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const handleSubmit = async (values) => {
    mutation.mutate(values)
  }

  return (
    <Container>
      <FeatureCard
        sx={{ marginTop: 2, p: 1 }}
        as={Link}
        to="../../release/add"
        Icon={CardsThree}
        name={i18n.t('start-create-track.heading')}
        description={i18n.t('start-create-track.description')}
      /> 
      <h1>{i18n.t('add-track')}</h1>
      <RecordingForm
        method="POST"
        onSubmit={handleSubmit}
        submitLabel={i18n.t('save-track')}
        saving={saving}
      />
    </Container>
  )
}