import React from 'react';
import { Container } from '@mui/joy';

import i18n from '@/i18n';
import { useState } from 'react';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { Waveform } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { ReleaseForm } from './[releaseId]/edit';
import { uploadCoverArtToRelease } from '@/actions/login';
import { FeatureCard } from '@/components/FeatureCard';
import { Link } from 'react-router-dom';
import { useManager } from '@/hooks';
import { createRelease } from '@/actions/aloader';
import { useMutation } from '@tanstack/react-query';

export default function AddReleasePage() {
  const { releaseId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddReleaseView releaseId={releaseId} />
    </React.Suspense>
  )
}

export function AddReleaseView() {
  const navigate = useNavigate()

  const manager = useManager()

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const addRelease = async (values) => {
    const image = values.image
    delete values["image_url"]
    const release = await createRelease(manager?.type, manager?.slug, values)
    if (image) {
      await uploadCoverArtToRelease(release.id, image)
    }
    return release
  }

  const mutation = useMutation({
    mutationFn: addRelease,
    onMutate: (values) => {
      setSaving(true)
    },
    onSettled: (data) => {
      setSaving(false)
      navigate(`../../release/${data.id}`)
    },
    onError: (error) => {
      setError(error)
      console.error(error)
    }
  })

  const handleSubmit = values => {
    mutation.mutate(values)
    return false
  }

  return (
    <Container>
      <FeatureCard
        sx={{ marginTop: 2, p: 1 }}
        as={Link}
        to="../../track/add"
        Icon={Waveform}
        name={i18n.t('create-track-release.heading')}
        description={i18n.t('create-track-release.description')}
      /> 
      <h1>{i18n.t('add-release')}</h1>
      <ReleaseForm
        saving={saving}
        submitLabel={i18n.t('add-release')}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}