
import React, { useRef, useState, useEffect } from 'react';

import { Box, Button, styled } from '@mui/joy';

import { InputField } from './InputField'

import { Img } from './Img'

import i18n from '@/i18n'

export const ImageFrame = styled(
  Box,
  {
    slot: 'root',
    name: "ImageFrame"
  }
)(({ theme }) => ({
  'img': {
    padding: 0,
    margin: 0,
    borderWidth: theme.variants.outlined.borderWidth,
    alignSelf: 'flex-start',
    aspectRatio: '1/1',
    borderColor: theme.variants.outlined.neutral.borderColor,
    borderStyle: 'solid',
  }
}))

export function ImageEditor({ 
 imageUrl: defaultImageUrl,
 readOnly,
 accept=".png,.jpg,.jpeg",
 onChange = () => console.warn('On change not implemented'),
 onChangeURL = () => console.warn('onChangeURL not implemented')
}) {
  const [imageUrl, setImageUrl] = useState(defaultImageUrl)
  useEffect(() => {
    setImageUrl(defaultImageUrl)
  }, [defaultImageUrl])
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null)
  const handleBrowseClick = (event) => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setFile(file)
    const reader = new FileReader()
    reader.onload = (e) => {
      setImageUrl(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  const handleRemoveClick = (event) => {
    setImageUrl(null)
  }

  useEffect(() => {
    onChangeURL(imageUrl)
  }, [imageUrl])
  useEffect(() => {
    onChange(file)
  }, [file])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2 }}>
      <ImageFrame>
        <Img src={imageUrl} style={{ objectFit: 'contain', aspectRatio: '1/1', margin: 0, padding: 0, width: '120pt' }} />
      </ImageFrame>
      <Button disabled={readOnly} variant="outlined" onClick={handleBrowseClick}>{i18n.t('browse')}</Button>
      <Button disabled={readOnly} variant="outlined" onClick={handleRemoveClick}>{i18n.t('remove')}</Button>
      <input type="file" accept={accept} onChange={handleFileInputChange} ref={fileInputRef} style={{ display: 'none' }} />
    </Box>
  )
}

export function ArtworkField({
  imageUrl,
  label,
  defaultValue,
  errors,
  info,
  onChange,
  readOnly,
  accept,
  onChangeURL
}) {
  return (
    <InputField
      errors={errors}
      info={info}
      label={label}
    >
      <ImageEditor accept={accept} readOnly={readOnly} defaultValue={defaultValue} onChangeURL={onChangeURL} imageUrl={imageUrl} onChange={onChange} />
    </InputField>
  )
}
