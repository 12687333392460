import React from 'react';
import { Container } from '@mui/joy';

import i18n from '@/i18n';
import { useState } from 'react';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { PersonForm } from './[personId]/edit';

import { useManager } from '@/hooks';
import { createPerson, uploadImageToNode } from '@/actions/aloader';
import { useMutation } from '@tanstack/react-query';

export default function AddPersonPage() {
  const { personId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddPersonView personId={personId} />
    </React.Suspense>
  )
}

export function AddPersonView() {
  const navigate = useNavigate()

  const manager = useManager()

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const addPerson = async (values) => {
    const image = values.image
    delete values["image_url"]
    const person = await createPerson(manager?.type, manager?.slug, values)
    if (image) {
      await uploadImageToNode('person', person.id, image)
    }
    return person
  }

  const mutation = useMutation({
    mutationFn: addPerson,
    onMutate: (values) => {
      setSaving(true)
    },
    onSettled: (data) => {
      setSaving(false)
      navigate(`../../person/${data.id}`)
    },
    onError: (error) => {
      setError(error)
      console.error(error)
    }
  })

  const handleSubmit = values => {
    mutation.mutate(values)
    return false
  }

  return (
    <Container>
      <h1>{i18n.t('add-person')}</h1>
      <PersonForm
        saving={saving}
        submitPerson={i18n.t('add-person')}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}