import React from 'react'

import { GenericList } from '@/components/GenericList'
import { useParams } from 'react-router-dom'
import { Img } from '@/components/Img'
import { Smiley } from '@phosphor-icons/react'

export function LabelsPage({
    getNodeHref=(node) => `/l/${node.slug}`,
    ...props
}) {
    const { labelId } = useParams()
    return (
        <GenericList
            isNodeActive={(node) => node.id == labelId}
            getNodeHref={getNodeHref}
            canAdd={false}
            showToolBar={false}
            path={`/labels`}
            viewType="menu"
            hasMoreMenu={false}
            showDefaultColumns={false}
            Icon={Smiley}
            columns={[
                {
                    id: 'image_url',
                    name: 'image',
                    type: 'image',
                    render: ({ node }) => <Img src={node.image_url} />
                },
                {
                    id: "name",
                    name: "name",
                    type: "name"
                }
            ]}
            {...props}
        />
    )
}

export default LabelsPage