import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

export const SessionContext = createContext({
  session: null,
  setSession: () => { },
  organization: null,
  setOrganization: () => { },
  user: null,
  theme: null,
  setTheme: () => {},
  setUser: () => {},
  label: null,
  artist: null,
  setArtist: () => {},
  person: null,
  setPerson: () => {},
  payee: null,
  setPayee: () => {},
  setLabel: () => {},
  hostname: 'localhost:3000',
  setHostname: () => {},
  role: null,
  setRole: (role) => {}
})

export function useLoadSession() {
  const { loadSession } = useContext(SessionContext)
  return loadSession
}

export function useSessionContext() {
  return useContext(SessionContext)
}

export function useHostname() {
  const { hostname } = useSessionContext()
  return hostname
}

export function useSession() {
  const { session } = useSessionContext()
  return session
}

export function useRole() {
  const { role } = useSessionContext()
  return role
}
