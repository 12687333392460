import { useState } from 'react';

import { createArtist } from '@/actions/aloader';

import { useMutation } from '@tanstack/react-query';

import { parseSpotifyUri } from '@/utils';

import i18n from '@/i18n';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/joy';
import { useManager } from '@/hooks';
import { SelectRelatedNode } from '@/components/SelectRelatedNode';
import { ArtistForm } from './[artistId]/edit';
import { Typography } from '@mui/joy'; 

export function CreateArtistPage() { 
    return (
        <CreateArtistContainer />
    )
}

export function CreateArtistContainer() {

    const manager = useManager()
    const [changeValues, setChangeValues] = useState({})
    const [creating, setCreating] = useState(false)
    const [created, setCreated] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: (values) => createArtist(manager?.type, manager?.id, values),
        onError: (error) => {
            setError(error)
        },
        onMutate: (values) => {
            setCreating(true)
        },
        onSuccess: (result) => {
            setCreating(false)
            setCreated(true)
            navigate(`../${node.id}`)
        }
    })

    const handleSubmit = changeValues => {
        setCreating(true)
        setCreated(false)
        setError(null)
        mutation.mutate({
            ...changeValues
        })
    }
    if (manager) {
        return (
            <Container>
                <Typography level="h1">{i18n.t('add-artist')}</Typography>
                <ArtistForm
                    loading={creating}
                    onSubmit={handleSubmit}
                />
            </Container>
        );
    }
}

export default CreateArtistPage;