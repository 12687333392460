import { ListItemButton, ListItemContent, ListItemDecorator } from "@mui/joy"
import { Link } from "react-router-dom"
import { BuildingOffice } from "@phosphor-icons/react"
import { AccountRow } from "./AccountRow"

export function AccountListItem({ account, ...props }) {
    return (
      <ListItemButton
        disabled={!account.can_view}
        component={Link}
        to={`/${account.node.type}/${account.node.slug}`}
        {...props}
      >
        <ListItemContent>
          <AccountRow account={account} />
        </ListItemContent> 
      </ListItemButton>
    )
}
  