import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Container, Box, Typography, Avatar, styled, Dropdown, MenuItem, Menu, MenuButton } from '@mui/joy';
import i18n from '@/i18n'
import { useManager, useUser } from '@/hooks';
import { Bell } from '@phosphor-icons/react';
import { Player } from './Player';
import { useContext } from 'react';
import { PlayerContext } from '@/contexts';

const AppHeaderRoot = styled(
  Box,
  {
    slot: 'root',
    name: 'AppHeader'
  }
)(({ theme }) => ({
  borderBottomWidth: theme.variants.outlined.borderWidth,
  borderBottomColor: theme.variants.outlined.neutral.borderColor,
  borderBottomStyle: 'solid',
  display: 'flex',
  padding: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1
}))

export function AppHeader({
  
}) {
  const user = useUser()
  const navigate = useNavigate()
  const { recording } = useContext(PlayerContext)

  return (
    <AppHeaderRoot sx={{ '@media screen and (max-width: 600px)': { display: 'none' } }}>
      <Container>
        <Box sx={{ display: 'flex', padding: 1, flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          {recording ? <Player /> : <Box sx={{ flex: 1 }} />} 
  
          {/*<Bell color="var(--brand-primary-background)" weight="bold" size={28} />*/}
          <Dropdown>
            <MenuButton variant="plain" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ display: 'none' }}>{user?.username ?? 'user'}</Typography>
              <Avatar src={user?.image_url} />
            </MenuButton>
            <Menu>
              <MenuItem onClick={() => navigate(`/${manager?.type}/${manager?.id}/user/${user?.id}`)}>{i18n.t('my-account')}</MenuItem>
              <MenuItem onClick={() => navigate('/accounts/logout')}>{i18n.t('logout')}</MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      </Container>
    </AppHeaderRoot>
  )
}