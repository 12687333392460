import { Skeleton } from '@/components/Skeleton';

import i18n from '@/i18n';
import { Container } from '@mui/joy';

import { GenericList } from '@/components/GenericList';

export function OrganizationsPage() {
  return (
    <OrganizationsContainer />
  )
}

export function OrganizationsSkeleton() {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
      </div>
    </Container>
  )
}

export function OrganizationsContainer() { 
    return (
        <GenericList
            canAdd={false}
            heading={i18n.t('organizations')}
            getNodeHref={(node) => `/organization/${node.slug}`}
            emptyText={`No organizations found`}
            filterPlaceholder={`filter-organizations`}
            path={`/organizations`}
            baseUrl={`/organization`}
        />
    )
}

export default OrganizationsPage
