import React, { useState } from 'react';
import { Box, Button, Container, Skeleton } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { Img } from '@/components/Img';

import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';

export function LabelView({ labelId }) {
  const hostname = useHostname()
  const navigate = useNavigate()

  const [values, setValues] = useState({})

  const { data: label, isLoading: loading } = useResource({
    queryKey: ['label', labelId],
    path: `/label/${labelId}`
  }) 

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
        <Skeleton loading={loading}>
          <h1>{label?.name ?? 'loading'}</h1>
        </Skeleton>
        <Button
          onClick={() => navigate(`edit`)}
          variant="outlined"
        >{i18n.t('edit')}</Button> 
        <Img src={label?.logotype_url} sx={{ marginTop: 1, width: '120pt' }} /> 
      </Box>
    </Container>
  )
}

export default function LabelPage() {
  const { labelId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <LabelView labelId={labelId} />
    </React.Suspense>
  )
}