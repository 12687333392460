import React from 'react';

import { gql } from '@apollo/client';

import localforage from 'localforage';

import client, { authorizedCoreFetch, authorizedFetch } from '@/client';
import { getAPIUrl } from '@/utils';

export async function downloadStatistics({ query, labelName, artistName = '', releaseName = '', recordingName = '' }) {
  const { saveAs } = await import('save-as'); 

  //window.open(`${getAPIUrl()}/api/statistics.csv?${querystring}&access_token=${token}`, '_blank')
  //return
  authorizedCoreFetch('GET', `/statistics.csv`, {}, { ...query }).then( res => res.blob() )
  .then( blob => {
    //document.write(blob) 
    var file = new File([blob], `Statistics_${labelName}_${artistName}_${releaseName}_${recordingName}_${query.start}-${query.end}.csv`)
    const fileUrl = window.URL.createObjectURL(file); 
    saveAs(blob, `Statistics_${labelName}_${query.start}-${query.end}.csv`)
  });
}

export async function uploadImageToLabel({ labelId, file }) {
  const token = await localforage.getItem('token');
  var data = new FormData()
  data.append('file', file)
  const response = await fetch(
    `${getAPIUrl()}/api/v2/label/${labelId}/image`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function uploadImageToNode({ nodeType, nodeId, files }) {
  const token = await localforage.getItem('token');
  var data = new FormData()
  for(let key of Object.keys(files)) {
    let file = files[key]
    data.append(key, file)
  }

  const response = await fetch(
    `${getAPIUrl()}/api/v2/${nodeType}/${nodeId}/image`,
    {
      body: data,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function checkISRCCode(isrc) {
  return await authorizedFetch('GET', `/check/isrc`, {}, { isrc })
}

export async function submitReleaseForReview(releaseId) {
  return await authorizedFetch('POST', `/release/${releaseId}/distribute`)
}

export async function updateUser(managerType, managerId, userId, user) {
  return await authorizedFetch('PUT', `/${managerType}/${managerId}/user/${userId}`, null, null, user)
}

export async function updatePerson(managerType, managerId, personId, values) {
  return await authorizedFetch('PUT', `/${managerType}/${managerId}/person/${personId}`, null, null, values)
}

export async function updatePayee(managerType, managerId, payeeId, values) {
  return await authorizedFetch('PUT', `/${managerType}/${managerId}/payee/${payeeId}`, null, null, values)
}

export async function updateArtist(managerType, managerId, artistId, values) {
  return await authorizedFetch('PUT', `/${managerType}/${managerId}/artist/${artistId}`, null, null, values)
}

export async function updateAccount(managerType, managerId, accountId, values) {
  return await updateNode(managerType, managerId, "account", accountId, values)
}

export async function updateNode(managerType, managerId, nodeType, nodeId, values) {
  return await authorizedFetch('PUT', `/${managerType}/${managerId}/${nodeType}/${nodeId}`, null, null, values)
}

export async function createNode(managerType, managerId, nodeType, values) {
  return await authorizedFetch('POST', `/${managerType}/${managerId}/${nodeType}`, null, null, values)
}

export async function createPerson(managerType, managerId, values) {
  return await createNode(managerType, managerId, 'person', values)
}

export async function updateRelease(managerType, managerId, id, values) {
  return await updateNode(managerType, managerId, "release", id, values)
}

export async function updateRecording(managerType, managerId, recordingId, values) {
  return await updateNode(managerType, managerId, "recording", recordingId, values)
}

export async function logLogin({
  username,
  success
}) {
  return await authorizedFetch('POST', `/logins`, {
    username,
    success
  })
}

export async function updateLabel(managerType, managerSlug, data) {
  const labelId = data.id
  let url =  `/${managerType}/${managerSlug}/label/${labelId}`
  if (managerType === 'label' && (managerSlug === data.slug || managerSlug === data.id)) {
    url = `/label/${labelId}`
  }
  return authorizedFetch('PUT',url, null, null, data)
}
 
export async function submitAdvanceInquiryFunding(advanceInquiryId, data) {
  return authorizedFetch('POST', '/submitAdvanceInquiryFunding', {}, {
    body: JSON.stringify({
      advanceInquiryId,
      ...data
    })
  })
}

export async function submitArtistAdvanceInquiry(data) {
  return authorizedFetch('POST', `/artist/${data.artist_id}/advance/inquiries`, {
    body: JSON.stringify({
      ...data
    })
  })
}

export async function submitAdvanceInquiry(data) {
  return authorizedFetch('POST', `/submitAdvanceInquiry`, {
    body: JSON.stringify({
      ...data
    })
  })
}

export async function submitAdvanceInquiryForReview(advanceInquiryId, data) {
  return authorizedFetch('POST', '/submitAdvanceInquiryReview', {}, {
    body: JSON.stringify({
      advanceInquiryId,
      ...data
    })
  })
}

export async function createLabel(managerType, managerSlug, data) {
  return authorizedFetch('POST', `/${managerType}/${managerSlug}/labels`, null, null, data)
}

export async function updateOrganization(data) {
  const organizationId = data.id
  return authorizedFetch('PUT', `/organization/${organizationId}`, null, null, data)
}

export async function createArtist(managerType, managerId, values) {
  const { data } = await createNode(managerType, managerId, 'artist', values)
  return data
}

export async function createPitch({
  trackId,
  pressText,
  pressKitUrl
}) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createPitch( 
        $trackId: ID!,
        $pressText: String!,
        $pressKitUrl: String!
      ) {
        createPitch(
          trackId: $trackId,
          pressText: $pressText,
          pressKitUrl: $pressKitUrl
        ) {
          pitch {
            id
            pressText
          }
        }
      }
    `,
    variables: {
      trackId,
      pressText,
      pressKitUrl
    }
  })
  return data.createPitch.pitch
}

export async function syncReleaseDownstream({ labelId, releaseId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation syncReleaseDownstream(
        $labelId: ID!
        $releaseId: ID!
      ) {
        syncReleaseDownstream(
          labelId: $labelId,
          releaseId: $releaseId
        ) {
          release {
            id
          }
        }
      }
    `,
    variables: {
      labelId,
      releaseId
    }
  })
  return data.syncReleaseDownstream
}

export async function inviteUser({
  managerType,
  managerId,
  label_id,
  email,
  label,
  organization,
  user_artists,
  payee_users,
  person_users
}) {
  const token = await localforage.getItem('token');
  var data = new FormData() 
  const response = await authorizedFetch(
    'POST',
    `/${managerType?.type}/${managerType.id}/invitations`, 
    null,
    null,
    {
      email,
      user_artists,
      payee_users,
      person_users,
      label,
      organization
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  const result = await response.json()
  return result
}

export async function redeemInvitation({
  invitationId
}) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation redeemInvitation( 
        $invitationId: ID!
      ) {
        redeemInvitation(
          invitationId: $invitationId
        ) {
          artist {
            id
          }
        }
      }
    `,
    variables: {
      invitationId
    }
  })
  return data.redeemInvitation.artist
}

export async function getMyLabels() {
  const result = await authorizedFetch('GET', `/user/me/labels`)
  return result
}

export async function fetchAudioProcessingStatus(recordingId) {
  const res = await authorizedFetch('GET', `/recording/${recordingId}/audio-processing-status`)
  return data.fetchAudioProcessingStatus

}

export async function getPitchById(pitchId) {
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query getPitchById($pitchId: ID!) {
        me {
          pitch(pitchId: $pitchId) {
            id
            pressText
            pressKitUrl
            trackId
            track {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      pitchId
    }
  })
  return data.me.pitch
}

export async function removePitch(pitchId) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation removePitch( 
        $pitchId: ID!
      ) {
        removePitch(
          pitchId: $pitchId
        ) {
          ok
        }
      }
    `,
    variables: {
      pitchId
    }
  })
  return data.createPitch.pitch
}

export async function createRecording(managerType, managerSlug, recording) {
  return await authorizedFetch('POST', `/${managerType}/${managerSlug}/recordings`, null, null, recording)
}

export async function createRelease(managerType, managerSlug, release) {
  return await authorizedFetch('POST', `/${managerType}/${managerSlug}/releases`, null, null, release)
}

export async function deleteRelease({ labelId, releaseId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createRelease($labelId: ID!, $releaseId: ID) {
        deleteRelease(labelId: $labelId, releaseId: $releaseId) {
          ok
        }
      }
    `,
    variables: {
      releaseId,
      labelId
    }
  })
  return data.deleteRelease
}

export async function createCategory({ labelId, name }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createCategory($labelId: ID!, $name: String!) {
        createCategory(labelId: $labelId, name: $name) {
          ok
        }
      }
    `,
    variables: {
      name,
      labelId
    }
  })
  return data.createCategory
}

export async function deleteVideo({ labelId, videoId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation deleteVideo($labelId: ID!, $videoId: ID) {
        deleteVideo(labelId: $labelId, videoId: $videoId) {
          ok
        }
      }
    `,
    variables: {
      videoId,
      labelId
    }
  })
  return data.deleteVideo
}

export async function createVideo({ labelId, name, embedUrl, description }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createVideo($labelId: ID!, $name: String!, $embedUrl: String!, $description: String) {
        createVideo(labelId: $labelId, name: $name, embedUrl: $embedUrl, description: $description) {
          video {
            id
            name
          }
        }
      }
    `,
    variables: {
      labelId,
      name,
      embedUrl,
      description
    }
  })
  return data.deletRelease
}

export async function deleteCategory({ labelId, categoryId }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createCategory($labelId: ID!, $categoryId: ID) {
        deleteCategory(labelId: $labelId, categoryId: $categoryId) {
          ok
        }
      }
    `,
    variables: {
      categoryId,
      labelId
    }
  })
  return data.deletVCategory
}

export async function removeTracksFromRelease(trackIds) {
  return await client.mutate({
    mutation: gql`
      mutation removeTracksFromRelease($trackIds: [ID]!) {
        removeTracksFromRelease(trackIds: $trackIds) {
          recordings {
            id
            name
          }
        }
      }
    `,
    variables: {
      trackIds
    }
  })
}

export async function removeReleases(releaseIds) {
  return await client.mutate({
    mutation: gql`
      mutation removeRelease($releaseIds: [ID]!) {
        removeReleases(releaseIds: $releaseIds) {
          ok
        }
      }
    `,
    variables: {
      releaseIds
    }
  })
}
