import React from 'react';

import i18n from '@/i18n';

import { GenericList } from '@/components/GenericList';

import { Smiley } from '@phosphor-icons/react';
import { Avatar, Container } from '@mui/joy';

export function UsersPage() {
  return <UsersView />
}

export function UsersView({
  q = '',
  viewType
}) {
  return (
    <Container>
      <GenericList
        path={`/users`}
        addLabel={i18n.t('add-user')}
        showDefaultColumns={false}
        filterPlaceholder={i18n.t('filter-users')}
        canSelectViewType={false}
        avatar={true}
        canChangeSortBy={false}
        viewType="list"
        canDelete={false}
        canEdit={true}
        Icon={Smiley}
        canView={false}
        columns={[
          {
            id: "image_url",
            name: i18n.t('image'),
            Icon: Smiley,
            type: "custom",
            render: ({ node }) => node.image_url ? <Avatar src={node.image_url} /> : <Smiley />
          },
          {
            id: 'email',
            name: i18n.t('email'),
            render: ({ node }) => node.email?.length > 0 ? node?.email : <span style={{opacity: 0.4}}>{node?.username}</span>
          }
        ]}
        heading={i18n.t('users')}
        addUrl={`../user/add`}
        baseUrl={`../user`}
      />
    </Container>
  )
}

export default UsersPage