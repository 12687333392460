import React, { useEffect } from 'react'

import { Skeleton } from '@/components/Skeleton';

import { Box, Container, List, ListItem } from '@mui/joy';

import { AccountListItem } from '@/components/AccountListItem';

import { VBox } from '@/components/VBox';
import { useResource } from '@/hooks';
import { useRef } from 'react';
import ErrorPage from '../error';

function Toggler({
  value = false,
  renderToggle,
  children
}) {
  const [open, setOpen] = React.useState(value);
  useEffect(() => {
    setOpen(value)
  }, [value])
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export function SelectAccountPage() {
  return (
    <SelectAccountContainer />
  )
}

export function SelectAccountSkeleton() {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
      </div>
    </Container>
  )
}
export function SelectAccountContainer() { 
  const {
    data: accountsData,
    error,
    isLoading,
    refetch
  } = useResource({
    queryKey: ['user', 'me', 'accounts'],
    path: '/user/me/accounts',
  })

  const itemsRef = useRef([])

  if (error) {
    return (
      <ErrorPage />
    )
  }

  const accounts = accountsData ?? []

  if (!(accounts instanceof Array)) {
    return (
      <>Error</>
    )
  }

  return (
    <VBox>
      <List sx={{ gap: 0.1, '--joy-spacing': '0pt' }}>
        {accounts.map((account, i) => { 
          return (
            <ListItem sx={{ transitionDelay: `${i * 10}ms`}} nested key={account.id}>
              <Toggler
                value={true}
                renderToggle={({ open, setOpen }) => (
                  <AccountListItem account={account} />
                )}
              >
                <List sx={{ gap: 0.5, marginLeft: 1 }}> 
                  {account?.label_users?.map(labelUser => (
                    <ListItem sx={{ transitionDelay: `${i * 10}ms`}} nested key={labelUser.id}>
                      <Toggler
                        value={true}
                        renderToggle={({ open, setOpen }) => (
                          <AccountListItem account={labelUser} />
                        )}
                      >
                        {labelUser?.artist_users?.map(artistUser => (
                          <ListItem sx={{ transitionDelay: `${i * 10}ms`}} nested key={artistUser.id}>
                            <Toggler
                              value={true}
                              renderToggle={({ open, setOpen }) => (
                                <AccountListItem account={artistUser} />
                              )}
                            >
                            </Toggler>
                          </ListItem>
                        ))}
                      </Toggler>
                    </ListItem>                    
                  ))}
                </List>
              </Toggler>
            </ListItem>
          )
        })}
      </List>
    </VBox>
  )
}

export default SelectAccountPage
