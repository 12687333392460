import { Outlet } from 'react-router-dom';

import { SessionContext } from '@/contexts';
import { useBrand } from '@/hooks';
import { useContext } from 'react';
import { useEffect } from 'react';
import buildTheme from '@/theme';
import { Box, GlobalStyles, LinearProgress } from '@mui/joy';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useBlocker } from 'react-router-dom';
import { DeveloperBar } from '@/components/DeveloperBar';
import { VBox } from '@/components/VBox';
import { useState } from 'react';

export default function Layout({ children }) {
  const { data: brand } = useBrand()

  const [brandId, setBrandId] = useState(null)

  const { theme, setTheme } = useContext(SessionContext)

  const isMutating = useIsMutating()

  const isDeveloperMode = process.env.NODE_ENV === 'development'

  const isFetching = useIsFetching()

  useEffect(() => { 
    if (brand) {
      if (brandId === `aloaded:${brand.type}:${brand.id}`) {
        return
      }
      if (brand?.is_branded) {
        setTheme(buildTheme({
          themeType: 'label',
          borderRadius: brand.style?.borderRadius ?? 0,
          brandPrimary: brand.color,
          color: brand.style?.color,
          backgroundColor: brand.style?.backgroundColor
        }))
      } else {
        setTheme(buildTheme({
          themeType: 'ALOADED',
          brandPrimary: '#000',
          borderRadius: brand.style?.borderRadius ?? 0,
          color: brand.style?.color,
          backgroundColor: brand.style?.backgroundColor
        }))
      }  
      setBrandId(`aloaded:${brand.type}:${brand.id}`)
    }
  }, [brand])

  const handleFavIcon = () => {
    if (brand) {
      if (brand && brand.is_branded) {
        if (brand.favicon_url) { 
          let link = document.querySelector("link[rel~='icon']");
          if (link) {
            link.parentNode.removeChild(link)
            link = null
          } 
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
          link.href = brand.favicon_url;
        }
        if (brand.name) {
          let titleEl = document.querySelector("title");
          if (!titleEl) {
            titleEl = document.createElement('title');
            document.getElementsByTagName('head')[0].appendChild(titleEl);
          }
          titleEl.innerHTML = brand.name
        }
      }
    }  
  }

  return (
    <>
      <GlobalStyles styles={(theme) => brand?.style?.transitions ? ({
        '*': {
          '.MuiButton-root': {
            'transition': 'background-color 1s',
          },
          'transition': 'opacity .5s ease-in',
          '@media (prefers-reduced-motion: no-preference)': {
            'transition': `
              opacity .5s ease-in, 
              scale   .5s ease-in,
              display .5s ease-in,
              transform .5s ease-in
            `,
            transitionBehavior: 'allow-discrete'
          },
          '@starting-style': { 
            'opacity': 0,
            transform: 'translateY(-5pt)'
          },
          '&[hidden]': {
            opacity: 0,
            scale: .9,
            display: 'none !important',
            'transition-duration': '.4s',
            'transition-timing-function': 'ease-out'
          }
        },
      }) : ({})}></GlobalStyles>
      <VBox sx={{ display: 'flex', height: '100vh', flexDirection: 'column', gap: 0, flex: 1, overflow: 'hidden' }}>
        {/*<DeveloperBar />*/}
        {isFetching || isMutating ? <LinearProgress style={{ zIndex: 1000, position: 'fixed', left: 0, top: 0, width: '100%', flex: 0 }}  /> : null}
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
      </VBox>
     
    </>
  );
}