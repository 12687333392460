import { Route, Navigate, Outlet } from "react-router-dom";

import ArtistPage from './artist/[artistId]';
import EditArtistPage from './artist/[artistId]/edit';
import AddArtistPage from './artist/add';
import RecordingsPage from './recordings';
import AddRecordingPage from './recording/add';
import EditRecordingPage from './recording/[recordingId]/edit';
import PeoplePage from './people';
import EditPersonPage from './person/[personId]/edit';
import PersonPage from './person/[personId]';
import PayeesPage from './payees'
import EditPayeePage from './payee/[payeeId]/edit'
import AddPayeePage from './payee/add'
import PayeePage from './payee/[payeeId]'
import LabelsPage from './labels'

import LabelPage from './label/[labelId]'
import EditLabelPage from './label/[labelId]/edit'
import EditReleasePage from './release/[releaseId]/edit';
import AddPersonPage from './person/add';
import RecordingPage from './recording/[recordingId]';
import MarketingPage from './marketing'
import MerchPage from './marketing/merch'
import PreSavePage from './marketing/pre-save'
import StatisticsPage from './statistics/index.jsx';
import PitchPage from './marketing/pitch'
import UsersPage from './users';
import { InviteUserPage } from './user/add';
import PromotionPage from './marketing/promotion'
import DiscoveryModePage from './marketing/discovery-mode'

import ReleasesPage from './releases';
import DashboardPage from '.';
import ArtistsPage from './artists';
import AddReleasePage from './release/add';
import ExternalPage from './docs/[slug]'

import { GuidesPage } from "./docs"
import OrganizationsPage from "./organizations";
import { EditUserPage } from "./user/[id]/edit";
import EditManagerPage from "./edit";
import { EditAccountPage } from "./account/[id]/edit";
import AccountsPage from "./accounts";
import AddLabelPage from "./label/add";

export default (<>
    <Route index element={<Navigate to="overview" replace />} />
    <Route path="edit" element={<EditManagerPage />} />
    <Route path="overview" element={<DashboardPage />} />
    <Route path="docs" element={<Outlet />}>
        <Route index element={<GuidesPage />} />
        <Route path=":slug" element={<Outlet />}>
            <Route index element={<ExternalPage />} />
        </Route>
    </Route>
    <Route path="guides" element={<Outlet />}>
        <Route index element={<GuidesPage />} />
        <Route path=":slug" element={<Outlet />}>
            <Route index element={<ExternalPage />} />
        </Route>
    </Route>
    <Route path="artist" element={<Outlet />}>
        <Route path=":artistId" element={<Outlet />}>
        <Route index element={<ArtistPage />} />
        <Route path="edit" element={<EditArtistPage />} />
        </Route>
        <Route path="add" element={<AddArtistPage />} />
    </Route>
    <Route path="releases" element={<ReleasesPage />} />
    <Route path="recordings" element={<RecordingsPage />} />
    <Route path="labels" element={<LabelsPage />} />
    <Route path="organizations" element={<OrganizationsPage />} />
    <Route path="label" element={<Outlet />}>
        <Route path=":labelId" element={<Outlet />}>
            <Route index element={<LabelPage />} />
            <Route path="edit" element={<EditLabelPage />} />
        </Route>    
        <Route path="add" element={<AddLabelPage />} />
    </Route>
    <Route path="people" element={<PeoplePage />} />
    <Route path="payees" element={<PayeesPage />} />
    <Route path="accounts" element={<AccountsPage />} />
    <Route path="account" element={<Outlet />}>
        <Route path="add" element={<InviteUserPage />} />
        <Route path=":accountId" element={<Outlet />}>
            <Route index element={<Navigate replace to="edit" />} />
            <Route path="overview" element={<Outlet />} />
            <Route path="edit" element={<EditAccountPage />} />
        </Route>
    </Route>
    <Route path="users" element={<UsersPage />} />
    <Route path="user" element={<Outlet />}>
        <Route path="add" element={<InviteUserPage />} />
        <Route path=":userId" element={<Outlet />}>
            <Route index element={<Navigate replace to="edit" />} />
            <Route path="overview" element={<Outlet />} />
            <Route path="edit" element={<EditUserPage />} />
        </Route>
    </Route>
    <Route path="payee" element={<Outlet />}>
        <Route path=":payeeId" element={<Outlet />}>
            <Route index element={<PayeePage />} />
            <Route path="edit" element={<EditPayeePage />} />
        </Route>
        <Route path="add" element={<AddPayeePage />} />
    </Route>
    <Route path="user" element={<Outlet />}>
    <Route path="add" element={<InviteUserPage />} />
    </Route>
    <Route path="recording" element={<Outlet />}>
        <Route path=":recordingId" element={<Outlet />}>
        <Route index element={<RecordingPage />} />
            <Route path="edit" element={<EditRecordingPage />} />
        </Route>
        <Route path="add" element={<AddRecordingPage />} />
    </Route>
    <Route path="track" element={<Outlet />}>
        <Route path=":recordingId" element={<Outlet />}>
            <Route index element={<RecordingPage />} />
            <Route path="edit" element={<EditRecordingPage />} />
        </Route>
        <Route path="add" element={<AddRecordingPage />} />
    </Route>
    <Route path="release" element={<Outlet />}>
        <Route path=":releaseId" element={<Outlet />}>
        <Route index element={<Navigate to="edit" replace />} />
        <Route path="edit" element={<EditReleasePage />} />
        </Route>
        <Route path="add" element={<AddReleasePage />} />
    </Route>
    <Route path="artists" element={<ArtistsPage />} />
    <Route path="person" element={<Outlet />}>
        <Route path=":personId" element={<Outlet />}>
            <Route index element={<PersonPage />} />
            <Route path="edit" element={<EditPersonPage />} />
        </Route>
        <Route path="add" element={<AddPersonPage />} />
    </Route>
    <Route path="marketing" element={<Outlet />}>
        <Route index element={<MarketingPage />} />
        <Route path="merch" element={<MerchPage />} />
        <Route path="promotion" element={<PromotionPage />} />
        <Route path="pre-save" element={<PreSavePage />} />
        <Route path="pitch" element={<PitchPage />} />
        <Route path="discovery-mode" element={<DiscoveryModePage />} />
    </Route>
    <Route path="statistics" element={<StatisticsPage />} />
</>);
