import React, { useState } from 'react'

import { Box, Button } from '@mui/joy'
import i18n from '@/i18n'
import { Check, X } from '@phosphor-icons/react'

import { TextField } from './InputField' 

import { addDoc, db, collection } from '@/client'
import { HBox } from './HBox'
import { useEffect } from 'react'
import { useOrganization } from '@/hooks'
import { useMutation } from '@tanstack/react-query'
import { createNode } from '@/actions/aloader'
import { useCallback } from 'react'

export function CreateNodeForm({
  nodeType,
  defaultName='',
  onNodeCreated,
  onClose,
  createNodeColumns
}) {
  const organization = useOrganization()
  const [values, setValues] = useState({
    name: defaultName
  })
  const [error, setError] = useState(false)
  const [status, setStatus] = useState('ready')

  useEffect(() => {
    setValues({
      name: defaultName
    })
  }, [defaultName])

  const mutation = useMutation({
    mutationFn: (values) => createNode('organization', organization.id, nodeType, values),
    onSettled: onNodeCreated,
    onError: (error) => {
      console.error(error)
    },
    onSuccess: () => {
      onClose()
    }
  })

  const handleSubmit = useCallback(event => {
    event.preventDefault()
    mutation.mutate(values)   
    return false
  }, [values])
  const updateValues = (inputValues) => {
    const newValues = {
      ...values,
      ...inputValues
    }
    setValues(newValues)
  }
  return (
    <>
      <Box sx={{ flexDirection: 'column', display: 'flex'}}>
        {createNodeColumns.map(createNodeColumn => {
          switch(createNodeColumn.type) {
          default:
            return (
              <TextField
                key={createNodeColumn.id}
                type={createNodeColumn.type}
                label={createNodeColumn.label}
                defaultValue={values[createNodeColumn.id]}
                name={createNodeColumn.id}
                onChange={(event) => updateValues({ [event.target.name]: event.target.value })}
                required={createNodeColumn.required}
                {...createNodeColumn.props ?? {}}
              />
            )
          }
        })}
      </Box>
      <HBox>
        <Button
          startDecorator={<Check />}
          onClick={handleSubmit}
        >
          {i18n.t('add-node', { nodeType })}
        </Button>
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          startDecorator={<X />}
          onClick={onClose}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('cancel')}</Button>
      </HBox>
    </>
  )
}