import React from 'react';
import { Container } from '@mui/joy';

import i18n from '@/i18n';
import { useState } from 'react';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { LabelForm } from './[labelId]/edit';

import { useManager } from '@/hooks';
import { createLabel, uploadImageToNode } from '@/actions/aloader';
import { useMutation } from '@tanstack/react-query';

export default function AddLabelPage() {
  const { labelId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddLabelView labelId={labelId} />
    </React.Suspense>
  )
}

export function AddLabelView() {
  const navigate = useNavigate()

  const manager = useManager()

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  const addLabel = async (values) => {
    const logotype = values.logotype
    delete values["logotype_url"]
    const label = await createLabel(manager?.type, manager?.slug, values)
    if (logotype) {
      await uploadImageToNode({ nodeType: 'label', nodeId: label.id, files: { logotype } })
    }
    return label
  }

  const mutation = useMutation({
    mutationFn: addLabel,
    onSettled: (data) => {
      setSaving(false)
      navigate(`../../label/${data.id}`)
    },
    onError: (error) => {
      setError(error)
      console.error(error)
    }
  })

  const handleSubmit = values => {
    mutation.mutate(values)
    return false
  }

  return (
    <Container>
      <h1>{i18n.t('add-label')}</h1>
      <LabelForm
        saving={saving}
        submitLabel={i18n.t('add-label')}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}