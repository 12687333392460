
import React from 'react'

import { GlobalStyles, Box } from '@mui/joy'
import { Outlet } from 'react-router-dom'
import { useManager } from '@/hooks'

export function EmbedLayout() {
    const manager = useManager()
    return (
        <>
            <GlobalStyles
                defaultTheme="dark"
                styles={(theme) => {
                    return ({
                        ...manager?.style
                    })
                }}
            />
            <Box sx={{ ...manager?.style?.body }} data-joy-color-scheme="dark">
                <Outlet />
            </Box>
        </>
    )
}