import React from 'react'

import { Table } from './Table'

import { File as FileIcon } from '@phosphor-icons/react'

import { useState } from 'react'
import { useEffect } from 'react'
import { Typography } from '@mui/joy'
import { Minus, Plus } from '@phosphor-icons/react'
import { createContext } from 'react'
import { VBox } from './VBox'
import RecordLabel from './RecordLabel'

export function TreeViewItem({ item, expanded: defaultExpanded = false, path }) {
    const [expanded, setExpanded] = useState(defaultExpanded)
    useEffect(() => { 
        setExpanded(defaultExpanded) 
    }, [defaultExpanded])
    let Icon = item.icon ?? FileIcon

    if (item.type === 'label') {
        Icon = RecordLabel
    }

    const { activePath, setActivePath } = useState([])

    const isActive = activePath instanceof Array ? activePath.join('/') === path.join('/') : activePath === path

    useEffect(() => {
        if (activePath instanceof Array && path instanceof Array) {
            if (activePath.join(':').startsWith(path.join(':'))) {
                setExpanded(true)
            }
        }
    }, [isActive])

    return (
        <React.Fragment key={item.id}>
            <tr style={{ cursor: 'pointer' }} className={isActive ? 'active' : ''}>
                <td style={{ width: 1, paddingLeft: `${(path?.length - 1) * 10}px` }}>
                    {item?.children?.length > 0 && (
                        <>
                            {expanded ? 
                                <Minus onClick={() => setExpanded(false)} /> :
                                <Plus onClick={() => setExpanded(true)} />
                            }
                        </>
                    )}
                </td>
                <td style={{ width: 1, paddingLeft: `${(path?.length - 1) * 10}px` }}>
                    <Icon />
                </td>
                <td style={{ paddingLeft: `${(path?.length - 1) * 10}px` }}>
                    <Typography>{item.name}</Typography>
                </td>
            </tr>
            {expanded && (
                item?.children?.map((child) => (
                    <TreeViewItem key={child.id} item={child} path={[...(path ?? []), item.type, item.id]} />
                ))
            )}
        </React.Fragment>
    )
}

export const TreeViewContext = createContext({
    activeItem: [],
    setActiveItem: () => {},
})

export function TreeView({
    items,
    activePath: defaultActivePath=[],
    ...props
}) {
    const [activePath, setActivePath] = useState(defaultActivePath)
    return (
        <TreeViewContext.Provider value={{ setActivePath, activePath }}>
            <VBox {...props}>
                <Table sx={{ height: 0}}>
                    <tbody tyle={{ overflow: 'scroll'}}>
                        {items.map((item, index) => (
                            <TreeViewItem key={item.id} item={item} level={0} />
                        ))}
                    </tbody>
                </Table>
            </VBox>
        </TreeViewContext.Provider>

    )
}