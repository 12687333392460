import React from 'react'

import { VBox } from '@/components/VBox'
import { CircularProgress } from '@mui/joy'

export function LoadingPage() {
    return (
        <VBox sx={{ alignItems: 'center', justifyContent: 'center', gap: 1, aspectRatio: '16/9' }}>
            <CircularProgress />
        </VBox>
    )
}

export default LoadingPage;