import { useRef, useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import useSWR from 'swr';

import { authorizedFetch, restFetcher } from "../client";

import { useQuery as useRestQuery, useSuspenseQuery as useSuspenseRestQuery, useInfiniteQuery as useInfiniteRestQuery, useSuspenseInfiniteQuery as useSuspenseInfiniteRestQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

export function useSearchQuery() {
  const query = useSearchParams()
  const navigate = useNavigate()
  const setSearchParams = (value, opts = { replace: false, shallow: false }) => {
    navigate({ query: value }, undefined, opts)
  }
  return [query, setSearchParams]
}

export function useAccounts() {
  return useResource({
    queryKey: ['user', 'me', 'accounts'],
    path: `/user/me/accounts`
  })
}

export function useUser() {
  const { data } = useResource({
    queryKey: ['user', 'me'],
    path: `/user/me`
  })
  return data
}

export function useOrganization() {
  const manager = useManager()

  if (manager?.type === 'organization') {
    return manager
  }
  if (manager?.type === 'label') {
    return manager?.organization
  }
  if (manager?.type === 'artist') {
    return manager?.label?.organization
  }
}

export function useManager() {
  const { pathname } = useLocation()

  const managerType = pathname.split('/')[1]
  const managerId = pathname.split('/')[2]
  
  const { data, isLoading } = useResource({
    queryKey: [managerType, managerId],
    path: `/${managerType}/${managerId}`,
    enabled: !!managerType && managerType !== 'accounts'
  })
  return data
}

export function useBrand() {
  const manager = useManager()

  const brandData = useResource({
    queryKey: ['brand'],
    path: `/brand`
  })

  let data = brandData?.data ?? {}

  if (manager) { 
    data = { ...data, ...manager }
  }

  console.table(data)

  return { data }
}

export function useBaseRoute() {
  const { pathname } = useLocation()

  let basePath = ''
  if (pathname.startsWith('/organization')) {
    basePath = `/organization/${pathname.split('/')[2]}`
  } else if (pathname.startsWith('/label')) {
    basePath = `/label/${pathname.split('/')[2]}`
  } else if (pathname.startsWith('/artist')) {
    basePath = `/artist/${pathname.split('/')[2]}`
  } else if (pathname.startsWith('/person')) {
    basePath = `/person/${pathname.split('/')[2]}`
  } else if (pathname.startsWith('/payee')) {
    basePath = `/payee/${pathname.split('/')[2]}`
  }
  return basePath
}

export function useIsAdminRoute() {
  const { hostname } = useLocation()
  return hostname?.startsWith('admin.')
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const [intervalRef, setIntervalRef] = useState(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  function tick() {
    savedCallback.current();
  }
  useEffect(() => {
    
  }, [delay]);
  const setActive = (active) => {
    if (active) {
      if (delay !== null) {
        const id = setInterval(tick, delay);
        setIntervalRef(id);
        return () => clearInterval(id);
      }
    } else {
      clearInterval(intervalRef);
    }
  }
  return [
    intervalRef,
    setActive
  ]
}

/*
export function useSearchParams() {
  const params = useParams()

  const searchParams = new URLSearchParams(params)
  const setSearchParams = (value, opts = { replace: false, shallow: false }) => {
    if (value instanceof Function) {
      const cb = value
      const searchParams = new URLSearchParams(params)
      const newSearchParams = cb(searchParams)
      const query = { ...params, ...Object.fromEntries(newSearchParams)}
      sh({ query }, undefined, opts)
    } else {
      const query = { ...params, ...value }
      router.push({ query }, undefined, opts);
    }
  }
  return [
    searchParams,
    setSearchParams,
    params
  ]
}
*/
export function useRest(path) {
  return useSWR(path, restFetcher)
}

export function useResource({ queryKey, path, enabled = true, params = null, ...otherProps }) {
  let defaultQuery = {}
  if (otherProps?.query instanceof Object) {
    defaultQuery = otherProps.query
  }

  let query = {}

  for (let k in defaultQuery) {
    if (typeof defaultQuery[k] !== 'undefined') {
      query[k] = defaultQuery[k]
    }
  }

  return useRestQuery({ queryKey, queryFn: () => authorizedFetch("GET", path, params, query), enabled, keepPreviousData: true, ...otherProps })
}

export function useSuspenseResource({ queryKey, path, enabled = true, params = null, ...otherProps }) {
  let defaultQuery = {}
  if (otherProps?.query instanceof Object) {
    defaultQuery = otherProps.query
  }

  let query = {}

  for (let k in defaultQuery) {
    if (typeof defaultQuery[k] !== 'undefined') {
      query[k] = defaultQuery[k]
    }
  }

  return useSuspenseRestQuery({ queryKey, queryFn: () => authorizedFetch("GET", path, params, query), enabled, keepPreviousData: true, ...otherProps })
}

export function useInfiniteResource({ queryKey, query = {}, path, enabled = true, params = null, ...otherProps }) {
  return useInfiniteRestQuery({
    queryKey,
    queryFn: ({ pageParam = ""}) => {
      const params = new URLSearchParams(pageParam?.split('?')?.[1])
      return authorizedFetch("GET", path, params, {
        ...query,
        offset: params.get('offset'),
        limit: params.get('limit')
      })
    },
    enabled,
    staleTime: 1 * 60 * 1000, // 1m
    keepPreviousData: false, // Keep data of current page instade of showing loading
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      // 1->2
      if (!(lastPage?.results?.length > 0)) {
        return undefined
      }
      return lastPage?.next
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      // 1->2
      if (!(firstPage?.results?.length > 0)) {
        return undefined
      }
      return firstPage?.previous
    },
    ...otherProps
  })
}

export function useSuspenseInfiniteResource({ queryKey, query = {}, path, enabled = true, params = null, ...otherProps }) {
  return useSuspenseInfiniteRestQuery({
    queryKey,
    queryFn: ({ pageParam = ""}) => {
      const params = new URLSearchParams(pageParam?.split('?')?.[1])
      return authorizedFetch("GET", path, params, {
        ...query,
        offset: params.get('offset'),
        limit: params.get('limit')
      })
    },
    enabled,
    staleTime: 1 * 60 * 1000, // 1m
    keepPreviousData: false, // Keep data of current page instade of showing loading
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      // 1->2
      if (!(lastPage?.results?.length > 0)) {
        return undefined
      }
      return lastPage?.next
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      // 1->2
      if (!(firstPage?.results?.length > 0)) {
        return undefined
      }
      return firstPage?.previous
    },
    ...otherProps
  })
}

export function useLocalStorage(key, defaultValue) {
  let storedValue = localStorage.getItem(key)
  try {
    storedValue = JSON.parse(storedValue) 
  } catch (e) {

  }

  const [value, setValue] = useState(storedValue ?? defaultValue)
  useEffect(() => {
    let saveValue = value
    try {
      localStorage.setItem(key, JSON.stringify(saveValue))
    } catch (e) {
      console.error(e)
      localStorage.setItem(key, value)
    }
  }, [value])
  return [value, setValue]
}

export function useSearchParam(key, defaultValue = null) {
  const [searchParams, setSearchParams] = useSearchParams()

  let value = defaultValue
  if (searchParams.has(key)) {
    let newValue = searchParams.get(key)
    value = newValue
  }
  if (value === "null") {
    value = null
  }

  const setValue = (value) => {
    if (value) {
      searchParams.set(key, value)
      setSearchParams(searchParams.toString())
      console.log("searchParams", searchParams.toString())
    }
  }

  return [value, setValue] 
}
