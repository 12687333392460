
import i18n from '@/i18n';

import { useState } from 'react';
import { TextField } from '@/components/InputField';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { Container } from '@mui/joy';
import { Button } from '@mui/joy';
import { Check } from '@phosphor-icons/react';
import { inviteUser } from '@/actions/aloader';
import { useEffect } from 'react';
import { useManager } from '@/hooks';

export function InviteUserPage() {
  return (
    <Container>
      <h1>{i18n.t('invite-user')}</h1>
      <InviteUserForm />
    </Container>
  )
}

export function InviteUserForm() {
  const manager = useManager()
  const [values, setValues] = useState({
    label_id: manager?.id,
    email: '',
    user_artists: []
  })

  useEffect(() => {
    updateValues({
      [manager?.type]: manager?.slug
    })
  }, [manager])

  const handleSubmit = event => {
    event.preventDefault()
    inviteUser(manager?.type, manager?.id, values).then((result) => {
      
    }).catch(e => {
      console.error(e)
    })

    return false
  }

  const updateValues = data => {
    const newValues = {
      ...values,
      ...data
    }
    setValues(newValues)
  }

  const canSubmit = values?.user_artists?.length > 0 && values?.email?.length > 0

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label={i18n.t('email')}
        required
        placeholder={i18n.t('enter-email')}
        onChange={event => {
          updateValues({ [event.target.name]: event.target.value })
        }}
      />
      {manager?.type in ['label', 'organization'] && (
        <SelectRelatedNodes
          label={i18n.t('artists')}
          ownerNodeId={null}
          nodeType="artist"
          ownerNodeType="user"
          addLabel={"add-artists"}
          allLabel={i18n.t('all-artists')}
          ownLabel={i18n.t('user-artists')}
          nodes={values?.user_artists ?? []}
          onChange={(user_artists) => updateValues({ user_artists })}
        />
      )}
      {manager?.type in ['label', 'organization'] && (
        <>
          <SelectRelatedNodes
            label={i18n.t('payees')}
            ownerNodeId={null}
            nodeType="payee"
            ownerNodeType="user"
            addLabel={"add-payees"} 
            allLabel={i18n.t('all-payees')}
            ownLabel={i18n.t('user-payees')}
            nodes={values?.payee_users ?? []}
            onChange={(payee_users) => updateValues({ payee_users })}
          /> 
          <SelectRelatedNodes
            label={i18n.t('people')}
            ownerNodeId={null}
            nodeType="person"
            ownerNodeType="user"
            addLabel={"add-person"}
            allLabel={i18n.t('all-people')}
            ownLabel={i18n.t('user-people')}
            nodes={values?.person_users ?? []}
            onChange={(person_users) => updateValues({ person_users })}
          />  
          <SelectRelatedNodes
            label={i18n.t('labels')} 
            ownerNodeId={null}
            nodeType="label"
            ownerNodeType="user"
            addLabel={"add-person"}
            allLabel={i18n.t('all-people')}
            ownLabel={i18n.t('user-people')}
            nodes={values?.label_users ?? []}
            onChange={(label_users) => updateValues({ label_users })}
          />   
        </>
      )}    
      <Button disabled={!canSubmit} startDecorator={<Check />} type="submit" variant="solid" color="primary">{i18n.t('invite-user')}</Button>
    </form>
  )
}