
import moment from 'moment/moment';

import { Box, Typography } from '@mui/joy';
import { Outlet } from 'react-router-dom';
import { useBrand, useManager, useResource } from '@/hooks';

export default function AccountsLayout({ children }) {
    const { data: manager } = useBrand()

    return (
      <ws-block>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ flex: 1 }}></Box>
          <img src={manager?.logotype_url} width="300pt" />
          <Typography level="h1" component="h1">Distribution</Typography>
          <Box style={{ flex: 1 }}></Box>
          <Outlet />
          <Box sx={{ flex: 1 }}></Box>
          <small style={{ margin: 10, opacity: 0.5 }}>Copyright &copy; {moment().format('YYYY')} {manager?.name}. All Rights Reserved.</small>
        </Box>
      </ws-block>
    )
}