import React, { useState, useCallback } from 'react';

import { Input, Table, Button, Box, Avatar, Modal, ModalDialog, Typography, ModalClose, Switch } from '@mui/joy';
import { Check, MagnifyingGlass, X } from '@phosphor-icons/react';

import { useInfiniteResource, useOrganization, useResource, useUser } from '@/hooks';
import { Img } from '@/components/Img';
import { InputField, InputFieldFrame } from './InputField';


import { Loading } from './Loading';
import { VBox } from './VBox';
import i18n from '@/i18n'
import { SelectNode } from './SelectNode';
import { CreateNodeForm } from './CreateNodeForm';
import { HBox } from './HBox';
import { useEffect } from 'react';

import { NodeCell } from './SelectRelatedNodes';
import { useManager } from '@/hooks';


export function SearchNodeList({
  junctionNodeType,
  nodeType,
  endpoint,
  emptyText,
  columns = [],
  createLabel,
  showName = true,
  selectedNodes: defaultSelectedNodes,
  createNodeColumns = [],
  junctionNodeColumns = [],
  isSubject = () => {},
  showIndexes = false,
  canCreate=false,
  onClose,
  allLabel = "allLabel",
  addLabel = "addLabel",
  ownLabel = "ownLabel",
  onChange = () => console.warn("SearchNodeList onchange not implemented"),
  onSubmit = () => console.warn('onSubmit')
}) {
  const [selectedNodes, setSelectedNodes] = useState(defaultSelectedNodes)

  const me = useUser()

  function isMe(node) {
    return node?.id === me?.id || node?.username === me?.username
  }

  const [createdNodes, setCreatedNodes] = useState([])

  const manager = useManager()

  useState(() => {
    setSelectedNodes(defaultSelectedNodes)
  }, [defaultSelectedNodes])
  const [creatingNewNode, setCreatingNewNode] = useState(false)
  const [q, setQ] = useState('') 
  const organization = useOrganization()
  const { data, hasNextPage, fetchNextPage, refetch, loading } = useInfiniteResource({
    queryKey: [junctionNodeType ?? nodeType, q],
    key: ['organization', organization?.slug, `${junctionNodeType ?? nodeType}?q=${q}`], path: endpoint ? `/${endpoint}` : `/${manager?.type}/${manager?.id}/${junctionNodeType ?? nodeType}`,
    query: {
      q
    },
    enabled: !!manager
  })
  useEffect(() => {
    refetch()
  }, [q])
  const toggleSelectNode = (node) => {
    const existingNode = selectedNodes.find(n => n?.[nodeType]?.id === node.id && !n.deleted)
    let newSelectedNodes = [...selectedNodes]
    if (!existingNode) {
      newSelectedNodes.push({
        [nodeType]: node
      })
    } else {
      for (const nod of selectedNodes.filter(n => n?.[nodeType]?.id === node.id)) {
        nod.deleted = true
      }
    }
    setSelectedNodes(newSelectedNodes)
  }

  const removeSelectedNode = node => {
    for (const nod of selectedNodes.filter(n => n?.[nodeType]?.id === node?.[nodeType].id)) {
      nod.deleted = true
    }
    setSelectedNodes([...selectedNodes])
  }

  const handleSubmit = useCallback(() => {
    onChange(selectedNodes)
    onSubmit(selectedNodes)
  }, [selectedNodes])

  const handleNodeCreated = newNode => {
    refetch()
    if (newNode instanceof Object) {
      setCreatedNodes([newNode])
    }
  }

  let nodes = data?.pages?.map(p => p.results ?? []).flat(1) ?? []

  if (nodes instanceof Array) {
    nodes = [...createdNodes, ...nodes]
  }

  return (
    <>
      <InputField
        label={ownLabel}
      >
        <InputFieldFrame sx={{ overflow: 'scroll', flex: 1 }}>
          <Table stickyHeader>
            <tbody>
              {selectedNodes?.length > 0 ? selectedNodes.filter(n => !n.deleted).map((node, i) => (
                <tr key={node.id}>
                  {showIndexes && (
                    <>
                      <td style={{ textAlign: 'right', opacity: 0.5, width: 40, padding: 8 }}>
                        <Typography sx={{ fontSize: 20}}>{(i + 1).toLocaleString().padStart(2, '0')}</Typography>
                      </td>
                    </>
                  )}
                  <td width={10}>
                    {isSubject(nodeType) ?
                      <Avatar src={node?.[nodeType]?.thumbnail_url} size={"sm"} />
                    :
                      <Img src={node?.[nodeType]?.thumbnail_url} style={{ height: '30pt' }} />
                    }
                  </td>
                  {showName && <td>{node?.[nodeType].name ?? node?.[nodeType]?.username}</td>}
                  {columns.map(column => (
                    <td key={column.id}><NodeCell column={column} node={node?.[nodeType]} /></td>
                  ))}
                  {junctionNodeColumns.map(junctionRowColumn => {
                    switch (junctionRowColumn.type) {
                    case "belongsTo":
                      return (
                        <td>
                          {!(nodeType === 'user' && isMe(me)) ? (
                            <SelectNode
                              node={node?.[junctionRowColumn.id]}
                              nodeType={junctionRowColumn.nodeType}
                              onChange={(value) => {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                                node[junctionRowColumn.id] = value
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }}
                            />
                          ) : (
                            <Typography>{node?.[junctionRowColumn.id]?.name}</Typography>
                          )}
                        </td>
                      )
                    case "belongsToMany":
                      return (
                        <td>
                          <SelectNode
                            multiple
                            placeholder={junctionRowColumn.placeholder}
                            node={node?.[junctionRowColumn.id]}
                            nodeType={junctionRowColumn.nodeType}
                            onChange={(value) => {
                              if (value instanceof Array) {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                                node[junctionRowColumn.id] = value
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }
                            }}
                          />
                        </td>
                      )
                    case "percentage":
                      return (
                        <td>
                          <InputFieldFrame>
                            <Input
                              variant="plain"
                              type={junctionRowColumn.type}
                              defaultValue={node?.[junctionRowColumn.id]}
                              placeholder={junctionRowColumn.placeholder}
                              sx={{ p: 0, flex: 1, '&.Mui-focused': { '--Input-focusedHighlight': 'none' }, 'input': { textAlign: 'right !important' } }}
                              onChange={(event) => {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n?.id !== node?.id)
                                node[junctionRowColumn.id] = parseFloat(event.target.value)
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }}
                            />
                            <Typography sx={{ p: 1}}>%</Typography>
                          </InputFieldFrame>
                        </td>
                      );
                    case "bool":
                      return (
                        <td>
                          <HBox>
                            <Switch
                              defaultChecked={node[attribute.id]}
                              onChange={(event) => {
                                const index = selectedNodes.indexOf(node)
                                const newSelectedNodes = selectedNodes.filter(n => n !== node)
                                node[junctionRowColumn.id] = event.target.checked
                                newSelectedNodes.splice(index, 0, node)
                                onChange(newSelectedNodes)
                              }}
                            />
                            <Typography>{node[junctionRowColumn.id] === true ? (junctionRowColumn.onLabel ?? junctionRowColumn.label) : (junctionRowColumn.offLabel ?? junctionRowColumn.label)}</Typography>
                          </HBox>
                        </td>
                      )
                    case "number":
                    default:
                      return (
                        <td>
                          <Input
                            type={junctionRowColumn.type}
                            defaultValue={node?.[junctionRowColumn.id]}
                            onChange={(event) => {
                              const index = selectedNodes.indexOf(node)
                              const newSelectedNodes = selectedNodes.filter(n => n !== node)
                              node[junctionRowColumn.id] = event.target.value
                              newSelectedNodes.splice(index, 0, node)
                              onChange(newSelectedNodes)
                            }}
                          />
                        </td>
                      )
                    }
                  })}
                  <td style={{ textAlign: 'right'}}>
                    {!(nodeType === 'user' && isMe(node?.[nodeType])) && <Button variant="outlined" onClick={() => removeSelectedNode(node)}>{i18n.t('remove')}</Button>}
                  </td>
                </tr>
              )): (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={3}>
                    {emptyText ?? <>No {nodeType}s added</>}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </InputFieldFrame>
      </InputField>
      <InputField
        label={allLabel}
      >
        <InputFieldFrame>
          <VBox>
            <HBox>
              <Input
                sx={{ flex: 1, margin: 1}}
                startDecorator={<MagnifyingGlass />}
                variant="outlined"
                defaultValue={q}
                onChange={(event) => {
                  setQ(event.target.value)
                }}
              />
              {canCreate && ( 
                <Button
                  variant="solid"
                  color="primary"
                  sx={{ marginRight: 1, marginLeft: 0 }}
                  onClick={() => setCreatingNewNode(true)}
                >
                  {createLabel ?? i18n.t('create-new-node', { nodeType })}
                </Button>   
              )}
            </HBox>
            {loading ? 
              <Loading />
            : nodes instanceof Array ?
              <Box sx={{ overflow: 'scroll' }}>
                <Table stickyHeader>
                  <tbody>
                    {nodes?.length > 0 ? nodes.map(node => (
                      <tr key={node.id}>
                        <td width={10}>
                          {isSubject(nodeType) ?
                            <Avatar src={node.thumbnail_url} size={"sm"} />
                          :
                            <Img src={node.thumbnail_url} style={{ height: '30pt' }} />
                          }
                        </td>
                        {showName && <td>{node.name ?? node.username}</td>} 
                        {columns?.map(column => (
                          <td key={column.id}>
                            <NodeCell column={column} node={node} />
                          </td>
                        ))}
                        <td style={{ textAlign: 'right' }}>
                          {!(nodeType === 'user' && isMe(node)) && <Button variant="outlined" onClick={() => toggleSelectNode(node)}>{i18n.t(selectedNodes.find(n => n[nodeType]?.id == node.id && !n.deleted) ? 'remove' : 'add')}</Button>}
                        </td>
                      </tr>
                    )): (
                      <tr>
                        <td style={{ textAlign: 'center' }} colSpan={3}>
                          No {nodeType}s found
                        </td>
                      </tr>
                    )}
                    {hasNextPage && (
                      <tr>
                        <td colSpan={3} style={{ textAlign: 'center' }}>
                          <Button
                            variant="outlined"
                            onClick={() => fetchNextPage()}
                          >
                            {i18n.t('load-more')}
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Box>
            : <>Error</>}
          </VBox>
        </InputFieldFrame>
      </InputField>
      <HBox>
        <Button
          variant="solid"
          startDecorator={<Check />}
          onClick={handleSubmit}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('done')}</Button>
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          startDecorator={<X />}
          onClick={onClose}
          sx={{ justifySelf: 'flex-start', alignSelf: 'flex-start' }}
        >{i18n.t('cancel')}</Button>
      </HBox>
      <Modal open={creatingNewNode} onClose={() => setCreatingNewNode(false)}>
        <ModalDialog>
          <ModalClose />
          <CreateNodeForm
            createNodeColumns={createNodeColumns}
            nodeType={nodeType}
            defaultName={q}
            onNodeCreated={handleNodeCreated}
            onClose={() => setCreatingNewNode(false)}
          />
        </ModalDialog>
      </Modal>
    </>
  )
}