import React, { useEffect, useState, useTransition } from 'react';

import moment from 'moment';

import { CircularProgress, Container } from '@mui/joy';

import i18n from '@/i18n';

import { Link } from 'react-router-dom';

import { useHostname } from '@/contexts';

import { gql } from '@apollo/client';

import Typography from '@mui/joy/Typography';

import Box from '@mui/joy/Box';


import { CardsThree, ChartBar, MusicNote, Smiley, Waveform } from '@phosphor-icons/react';
import { useSearchParams } from 'react-router-dom';
import { FeatureCard } from '@/components/FeatureCard';
import { useResource } from '@/hooks';
import { useManager } from '@/hooks';

export const QUERY = gql`
  query getStatistics(
    $start: Date!,
    $end: Date!,
    $hostname: String!,
    $filters: SegmentFilterInputArgument
  ) {
    me {
      id
      label(
        domain: $hostname
      ) {
        draftReleases: releases(isSubmitted: false) {
          id
          name
          thumbnailUrl
          artists {
            id
            name
          }
          released
          colorScheme {
            primary {
              dark
              normal
              light
            }
            secondary {
              dark
              normal
              light
            }
          }
        }
        directStatistics(
          start: $start,
          end: $end,

          filters: $filters,
          groupBy: "day"
        ) {
          start
          end
          downloads
          streams

          services {
            id
            name
          }
          artists {
            id
            name
          }
          releases {
            id
            name
          }
          recordings {
            id
            name
          }
          stats {
            date
            downloads
            streams
          }
        }
        id
        name
      }
    }
  }
`

export function DashboardPage() {
  const hostname = useHostname()

  const query = useSearchParams()
  const [q, setQ] = useState(query.q)
  let {
    start,
    end,
    filters
  } = query
  if (!end) {
    end = moment().subtract(2, 'days').format('YYYY-MM-DD')
  }
  if (!start) {
    start = moment(end).subtract(7, 'days').format('YYYY-MM-DD')
  }

  return (
    <Container>
      <React.Suspense fallback={
        <div className="central">
          <CircularProgress />
        </div>
      }>
        <DashboardView
          hostname={hostname}
          start={start}
          end={end}
          filters={filters}
          q={q}
        />
      </React.Suspense>
    </Container>
  )
}

export function DashboardView({
  start: defaultStart,
  end: defaultEnd,
  hostname,
  filters
}) {
  const manager = useManager()
  const [isPending, startTransition] = useTransition()
  const { data, refetch, isLoading, isRefetching, isPreviousData } = useResource({
    queryKey: [manager?.type, manager?.slug, 'releases'],
    path: `/${manager?.type}/${manager?.slug}/releases`,
    enabled: !!manager
  })
  useEffect(() => {
    if (manager) {
      refetch()
    }
  }, [manager])
  const releases = data?.results ?? []
  return (
    <Container>
      <Typography level="h2" component="h1">
        {i18n.t('welcome-to-distribution-title', { name: manager?.name ?? 'distribution' })}
      </Typography>
      <br />
      <Box sx={{ display: 'grid', gap: 1,  gridTemplateColumns: '1fr 1fr' }}> 
        {releases?.length < 1 && <FeatureCard
          as={Link}
          to={`../release/add`}
          Icon={Waveform}
          name={i18n.t('create-your-first-release')}
          description={i18n.t('create-your-first-release-summary')}
        />}
        <FeatureCard
          as={Link}
          to="../releases"
          Icon={CardsThree}
          name={i18n.t('releases')}
          description={i18n.t('all-your-releases')}
        />
        <FeatureCard
          as={Link}
          Icon={Smiley}
          to="../artists"
          name={i18n.t('artists')}
          description={i18n.t('browse-artists')}
        /> 
        <FeatureCard
          as={Link}
          to="../statistics"
          Icon={ChartBar}
          name={i18n.t('statistics')}
          description={i18n.t('follow-your-releases')}
        /> 
        <FeatureCard
          as={Link}
          Icon={MusicNote}
          to="../recordings"
          name={i18n.t('tracks')}
          description={i18n.t('tracks')}
        /> 
        {/*<FeatureCard
          as={Link}
          to="../discovery-mode"
          Icon={Binoculars}
          name={i18n.t('marketing')}
          description={i18n.t('marketing-summary')}
        />*/}
      </Box>
    </Container>
  )
}
 
export default DashboardPage;