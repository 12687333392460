import React from 'react';
import { Box, Button, Container } from '@mui/joy';

import i18n from '@/i18n';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { Img } from '@/components/Img';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';
import { Do, Empty, ForEach } from '@/components/Syntax';
import { useManager } from '@/hooks';
import ErrorPage from '@/pages/error';
import LoadingPage from '@/pages/loading';

export function PayeeView({ payeeId }) {
  const manager = useManager()

  const { data: payee, isLoading, error, refetch } = useResource({
    queryKey: [manager?.type, manager?.slug, 'payee', payeeId],
    path: `/${manager?.type}/${manager?.slug}/payee/${payeeId}`,
    enabled: !!manager
  })

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (payee) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{payee.name}</h1>
          <Button
            component={Link}
            to={`../../payee/${payee.id}/edit`}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <Img src={payee.image_url} style={{ marginTop: 1, width: '120pt' }} />
          <h3>{i18n.t('users')}</h3>
          <HBox>
            <ForEach collection={payee.payee_users}>
              <Do>
                {member => (
                  <UserView key={member.id} user={member.user} />
                )}
              </Do>
              <Empty>
                <i>Payee has no users</i>
              </Empty>
            </ForEach>
          </HBox>
        </Box>
      </Container>
    )
  } else {
    return (
      <LoadingPage />
    )
  }
}

export default function PayeePage() {
  const { payeeId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <PayeeView payeeId={payeeId} />
    </React.Suspense>
  )
}