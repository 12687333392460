import React from 'react'

import { Container, Box, Typography, Avatar, styled, Dropdown, MenuItem, Menu, MenuButton } from '@mui/joy';

import { useManager } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useResource } from '@/hooks'

import i18n from '@/i18n'
import { AccountRow } from './AccountRow'
import { HBox } from './HBox';

export function AccountBanner({
    expanded,
    account
}) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, aspectRatio: '16/6' }}>
            {expanded ? (
                <>
                    {account.node.banner_url && ( 
                        <img src={account?.node.banner_url ?? account?.node.image_url} style={{ width: '100%' }} />
                    )}
                    {account.node.avatar_url && (
                        <HBox>
                            <Avatar size={"lg"} src={account?.node.avatar_url} />
                            <Typography>{account.node.name}</Typography>
                        </HBox>
                    )}
                </>
            ) : (
                account.node.avatar_url ? ( 
                    <Avatar size={"sm"} src={account.node.avatar_url} />
                
                ) : (
                    <img src={account?.node.icon_url ?? account.node.banner_url ?? account.node.logotype_url} style={{ width: '48px' }} />
                )
            )}
        </Box>
    )
}

export function AccountSelect({
    expanded
}) {
    const navigate = useNavigate()
    const manager = useManager()

    const { data: accountsData } = useResource({
      queryKey: ['user', 'me', 'accounts'],
      path: `/user/me/accounts`,
      enabled: !!manager
    })

    if (!accountsData || !manager) {
        return null
    }

    let organizationUser = null
    let labelUser = null
    let managerUser = null

    for (const _organizationUser of accountsData) {
        organizationUser = _organizationUser
        for (const _labelUser of _organizationUser.label_users) {
            if (_labelUser?.node?.id === manager.id && manager?.type === 'label') {
                organizationUser = _organizationUser
                managerUser = _labelUser
                labelUser = _labelUser
                console.table("manager", manager)
            }
            if (_labelUser.artist_users instanceof Array) {
                for (const _artistUser of _labelUser.artist_users) {
                    if (_artistUser?.node?.id === manager.id && manager?.type === 'artist') {
                        organizationUser = _organizationUser
                        labelUser = _labelUser
                        managerUser = _artistUser 
                    }
                }
            }
            if (_labelUser.person_users instanceof Array) {
                for (const _personUser of _labelUser.person_users) {
                    if (_personUser?.node?.id === manager.id && manager?.type === 'person') {
                        organizationUser = _organizationUser
                        labelUser = _labelUser
                        managerUser = _personUser
                    }
                }
            }
            if (_labelUser.payee_users instanceof Array) {
                for (const _payeeUser of _labelUser.payee_users) {
                    if (_payeeUser?.node?.id === manager.id && manager?.type === 'payee') {
                        organizationUser = _organizationUser
                        labelUser = _labelUser
                        managerUser = _payeeUser
                    }
                }
            }
        }
    }

    return (
        <Dropdown>
            <MenuButton variant="plain" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                {organizationUser && !labelUser &&  <AccountBanner account={organizationUser} expanded={expanded} />}
                {labelUser && <AccountBanner account={labelUser} expanded={expanded} />}
                {['artist', 'person', 'payee'].indexOf(manager?.type) !== -1 && managerUser && <AccountBanner account={managerUser} expanded={expanded} />}
            </MenuButton>
            <Menu>
                <MenuItem disabled={true}>{i18n.t('accounts')}</MenuItem>
                {accountsData.map((organizationUser) => (
                    <React.Fragment key={organizationUser.id}> 
                        <MenuItem
                            disabled={!organizationUser.can_view}
                            selected={organizationUser.node.type === manager?.type && organizationUser.node.slug === manager?.slug}
                            key={organizationUser.id}
                            onClick={() => navigate(`/${organizationUser.node.type}/${organizationUser.node.slug}`)}
                        > 
                            <AccountRow account={organizationUser} /> 
                        </MenuItem>
                        {organizationUser?.label_users instanceof Array && organizationUser?.label_users.map((labelUser) => (
                            <React.Fragment key={labelUser.id}>
                                <MenuItem disabled={!labelUser.can_view} sx={{ paddingLeft: 3 }} key={labelUser.id} onClick={() => navigate(`/${labelUser.node.type}/${labelUser.node.slug}`)}>
                                    <AccountRow account={labelUser} />
                                </MenuItem>
                                {labelUser?.artist_users instanceof Array && labelUser?.artist_users.map((artistUser) => (
                                    <MenuItem sx={{ paddingLeft: 5 }} key={artistUser.id} onClick={() => navigate(`/${artistUser.node.type}/${artistUser.node.id}`)}>
                                        <AccountRow account={artistUser} />
                                    </MenuItem>
                                ))}
                            </React.Fragment>
                        ))} 
                    </React.Fragment>
                ))}
                <hr />
                {accountsData?.length > 5 && <MenuItem onClick={() => navigate('/accounts/select')}>{i18n.t('more-accounts')}</MenuItem>}
                <hr />
                <MenuItem onClick={() => navigate('/accounts/logout')}>{i18n.t('logout')}</MenuItem>
            </Menu>
        </Dropdown>
    )
}