import React from 'react'

import i18n from '@/i18n'

import { VBox } from '@/components/VBox'
import { Button, Typography } from '@mui/joy'
import { XCircle } from '@phosphor-icons/react'


export function NotFoundPage({
    onTryAgainClick
}) {
    return (
        <VBox sx={{ alignItems: 'center', justifyContent: 'center', aspectRatio: '16/9' }}>
            <XCircle size={128} />
            <Typography level="h1">{i18n.t('not-found-page.heading')}</Typography>
            <Typography>{i18n.t('not-found-page.text')}</Typography>
            <Button onClick={() => history.back()}>{i18n.t('go-back')}</Button>
        </VBox>
    )
}

export default NotFoundPage;