
import React from 'react'

import Handlebars from 'handlebars'

import { useResource } from '@/hooks'
import { Container, Typography } from '@mui/joy'
import { LoadingPage } from '@/components/LoadingPage'
import { ErrorView } from '@/components/ErrorView'
import { useParams } from 'react-router-dom'

import { ContentBlockContext, PageBlock } from '@/components/Content/ContentBlock'
import { useManager } from '@/hooks'

export function ExternalPage() {
    const { slug } = useParams()
    return (
        <ExternalPageView slug={slug} />
    )
}

export function ExternalPageView({
    slug
}) {
    const manager = useManager()
    const { data: page, isLoading } = useResource({
        queryKey: ['page', slug],
        path: `/page/${slug}`
    })

    if (isLoading) {
        return (
            <LoadingPage />
        )
    }

    if (page) {
        return (
            <Container>
                <ContentBlockContext.Provider value={{ data: { label: manager } }}>
                    <PageBlock {...page} />
                </ContentBlockContext.Provider>
            </Container>
        )
    } else {
        return <ErrorView />
    }
}

export default ExternalPage;