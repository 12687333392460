import React, { useState } from 'react';
import { Box, Button, CircularProgress, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n';

import { Loading } from '@/components/Loading';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';

import { useNavigate } from 'react-router-dom';
import { useManager } from '@/hooks';
import { updatePerson, uploadImageToNode } from '@/actions/aloader';
import LoadingPage from '@/pages/loading';
import ErrorPage from '@/pages/error';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { ArtworkField } from '@/components/ArtworkField';
import { useMutation } from '@tanstack/react-query';

export default function EditPersonPage() {
  const { personId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditPersonView personId={personId} />
    </React.Suspense>
  )
}

export function PersonForm({
  person,
  values: defaultValues = {},
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = (event) => {
    event.preventDefault()

    onSubmit(values)
    return false
  }

  useEffect(() => {
    if (person) {
      setValues({
        ...person
      })
    }
  }, [person])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box>
        <TextField
          name="name"
          defaultValue={values?.name}
          label={i18n.t('person-name')}
          placeholder={i18n.t("enter-person-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <ArtworkField
          name="image"
          imageUrl={values?.image_url}
          label={i18n.t('avatar')}
          onChange={(value) => setValues({ ...values, image: value })}
        />
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          placeholder={i18n.t('add-users')}
          ownerNodeType="label"
          ownerNodeId={values?.id}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('label-s-users')}
          showName={false}
          nodes={values?.person_users ?? []}
          canCreate={true}
          createNodeColumns={[
            {
              label: i18n.t('first-name'),
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              label: i18n.t('last-name'),
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              label: i18n.t('email'),
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          junctionNodeColumns={[
            {
              id: "role",
              name: i18n.t("role"),
              type: "belongsTo",
              nodeType: "role"
            }
          ]}
          columns={[
            {
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          onChange={(person_users) => {
            setValues({ ...values, person_users })
          }}
        />
        <Button type="submit" startDecorator={<Check />} variant="solid">{i18n.t('save-person')}</Button>
      </Box>
    </form>
  )
}

export function EditPersonView({
  personId
}) {
  const manager = useManager()
  const navigate = useNavigate()

  const {
    data: person,
    isLoading,
    error,
    refetch
  } = useResource({
    queryKey: ['person', personId],
    path: `/${manager?.type}/${manager?.slug}/person/${personId}`,
    enabled: !!manager
  })

  const savePerson = async (values) => {
    const result = await updatePerson(manager?.type, manager?.id, person.id, values)
    if (values?.image) {
      await uploadImageToNode({ nodeType: 'person', nodeId: result.id, files: { image: values.image }})
    }
    return result
  }

  const mutation = useMutation({
    mutationFn: savePerson,
    onSettled: (data) => {
      setSaving(false)
      navigate(`../../person/${data.id}`)
    },
    onError: (error) => {
      setError(error)
      console.error(error)
    }
  })

  const handleSubmit = (values) => {
    console.log(values)
    mutation.mutate(values)
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    )
  }

  if (person) {
    return (
      <Container>
        <HBox component={Link} to="..">
          <ArrowLeft />
          <Typography>{person?.name}</Typography>
        </HBox>
        <PersonForm
          person={person}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  } else {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    )
  }
}