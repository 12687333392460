import React, { createContext, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { useBaseRoute, useInfiniteResource } from '@/hooks'
 
import { Select, Typography, Box, Button, Input, Avatar, MenuButton, IconButton, Menu, MenuItem, Option, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent, Modal, ModalDialog, ModalClose, } from '@mui/joy' 
import { ViewTypeSelect } from './ViewTypeSelect'

import i18n from '@/i18n'
import { useHostname } from '@/contexts'
import { useContext } from 'react'
import { NodeCard } from './NodeCard'
import { CaretDown, CaretUp, Eye, MagnifyingGlass, MusicNote, Pencil, Plus, Trash, Warning } from '@phosphor-icons/react'
import { Loading } from './Loading'
import { useEffect } from 'react'
import { Case, Do, Empty, ForEach, SwitchCase } from './Syntax'
import { Grid, Table, Skeleton } from '@mui/joy'
import { Link } from 'react-router-dom'
import { Dropdown } from '@mui/joy'
import { MoreHoriz } from '@mui/icons-material'
import { EmptyView } from './EmptyView'
import { Img } from './Img'
import { NavLink } from 'react-router-dom'
import { VBox } from './VBox'
import { SelectRelatedNode } from './SelectRelatedNode'
import { Funnel } from '@phosphor-icons/react/dist/ssr'
import { useMediaQuery } from '@mui/material'

export const GenericListContext = createContext({
  nodes: [],
  q: '',
  setQ: () => {},
  viewType: '',
  setViewType: () => {},
  columns: [],
  sortBy: '',
  setSortBy: () => {},
  path: ''
})

export function GenericListRow({ onClick, onMouseDown, Icon, node, number: i }) {
  const navigate = useNavigate()
  if (!node) {
    return <tr />
  }
  const {
    queryKey,
    query: defaultQuery,
    path,
    empty,
    baseUrl,
    isMobile,
    canView,
    isNodeActive,
    canEdit,
    canDelete,
    canPaginate,
    showDefaultColumns,
    avatar,
    filters,
    showImages,
    q,
    hasMoreMenu,
    type,
    columns, 
    setQ,
    sortBy,
    emptyText,
    setSortBy,
    viewType,
    setViewType
  } = useContext(GenericListContext)

  const active = isNodeActive instanceof Function ? isNodeActive(node) : isNodeActive == true

  return (
    <tr onClick={onClick} onMouseDown={onMouseDown} style={{ transitionDelay: `${i * 100}ms`}} className={active ? 'row-active' : ''}>
      {showDefaultColumns && (
        <>
          <td width={10}>   
            {avatar ? <Avatar src={node.image_url} style={{ aspectRatio: '1/1', height: '32pt'}} /> : 
              (showImages && node.image_url) ? (
                <Img 
                  src={node.image_url}
                  style={{ objectFit: 'contain', width: '50pt' }}
                />
              ) : (
              <Icon size={32} />
            )}
          
          </td>
          <td>
            <Typography>{node.name || <i>**No name**</i>}</Typography>
          </td>
        </>
      )}
      {columns.filter(c => !isMobile || c.sm).map(column => {
        if (column.id === 'name' && isMobile) {
          return (
            <td key={column.id}>
              <VBox sx={{ flex: 1, alignSelf: 'stretch', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Typography>{node[column.id]}</Typography>
                <Typography sx={{ opacity: 0.5 }}>
                  {node.type ?? type} {columns.filter(c => c.type === 'belongsToMany').map(c => {
                    if (!c) return
                    return c.map(n => (
                        <> · <Link to={`/${baseUrl}/${n.type}/${n?.slug ?? n?.id}`}>{n?.name}</Link></>
                    ))
                  })} {columns.filter(c => c.type === 'belongsTo').map(c => {
                    if (!c) return
                    return (
                      <> · <Link to={`/${baseUrl}/${c.type}/${c?.slug ?? c?.id}`}>{c?.name}</Link></>
                    )
                  })}
                </Typography>
              </VBox>
            </td>
          )
        }
      return (
          <td key={column.id}>{renderColumn({ type, Icon, column, node })}</td>
        )
      })}
      {hasMoreMenu && <td style={{ textAlign: 'right'}}>
        <Dropdown>
          <MenuButton
          sx={{ opacity: 0.5 }}
            slots={{ root: IconButton }}
            slotProps={{ root: { color: 'neutral' } }}
          >
            <MoreHoriz />
          </MenuButton>
          {(canView || canDelete || canEdit) && <Menu placement="bottom-end">
            {canView && <MenuItem onClick={() => navigate(`${node.id}`)}>  
              <Eye />{i18n.t('view')}
            </MenuItem>}
            {canEdit && <MenuItem onClick={() => navigate(`${baseUrl}/${node.id}/edit`)}>  
              <Pencil />{i18n.t('edit')}
            </MenuItem>}
            {canDelete && <MenuItem sx={{ color: "danger" }} onClick={() => deleteNode(node.id)}>  
              <Trash />{i18n.t('delete')}
            </MenuItem>}
          </Menu>}
        </Dropdown>
      </td>}
    </tr>
  )
}
export function GenericList({
  queryKey,
  path,
  avatar = false,
  Icon = MusicNote,
  canAdd=true,
  canPaginate=true,
  isNodeActive=() => {},
  showToolBar=true,
  canSearch=true,
  query : defaultQuery = {},
  filterColumns,
  baseUrl,
  showImages=false,
  addUrl,
  type='node',
  canDelete=false,
  canEdit=false,
  canView=true,
  sortByFields=[],
  showDefaultColumns=true,
  heading,
  canChangeSortBy=true,
  canSelectViewType=true,
  addLabel,
  getNodeHref = () => { throw new Error("Not implemented")},
  filterPlaceholder,
  hasMoreMenu=true,
  emptyText = 'Nothing found',
  columns=[],
  NodeCardComponent=NodeCard,
  viewType: defaultViewType = 'grid',
  sortBy: defaultSortBy = 'name',
  filters: defaultFilters = {}
}) {
  const [q, setQ] = useState('')
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const [viewType, setViewType] = useState(defaultViewType)
  const [showingFiltersDialog, setShowingFiltersDialog] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const isMobile = useMediaQuery('(max-width: 720pt)')

  const updateFilters = (values) => {
    const newValues = {
      ...filters,
      ...values
    }
    setFilters(newValues)
  } 

  const navigate = useNavigate()


  const filterChildren = (
    <>
      {canSearch && <Input
        sx={{ transitionDelay: `200ms`}}
        startDecorator={<MagnifyingGlass weight="bold" />}
        defaultValue={q}
        onChange={(event) => setQ(event.target.value)}
        placeholder={i18n.t(filterPlaceholder)}
      />}
      {canChangeSortBy && (
        <Select
          sx={{ transitionDelay: `300ms`, minWidth: 200 }}
          placeholder={i18n.t('sort-by')}
          defaultValue={sortBy}
          onChange={(event, value) => setSortBy(value)}
        >
          {sortByFields.map(field => (
            <Option value={field.id}>{field.name}</Option>
          ))}
        </Select>
      )}
      {filterColumns?.map(column => {
        if (!column) return
        switch(column.type) {
          case "select":
            return (
              <Select
                sx={{ transitionDelay: `300ms`, minWidth: 200 }}
                placeholder={column.placeholder ?? column.name}
                defaultValue={filters[column.id] ?? ''}
                onChange={(event, value) => updateFilters({ [column.id]: value })}
              >
                {column?.options?.map(option => (
                  <Option value={option.id}>{option.name}</Option>
                ))}
              </Select>
            )
          case "belongsTo":
            return (
              <SelectRelatedNode
                sx={{ transitionDelay: `300ms`, minWidth: 200 }}
                nodeType={column.nodeType}
                placeholder={column.placeholder ?? column.placeholder}
                defaultValue={filters[column.id] ?? ''}
                onChange={(value) => updateFilters({ [column.id]: value?.slug ?? value?.id ?? value })}
              />
            )
          default:
            return <></>
        }
      })}
      
    </>
  )

  return (
    <GenericListContext.Provider
      value={{
        path,
        columns,
        hasMoreMenu,
        q,
        emptyText,
        avatar,
        canPaginate,
        isMobile,
        canView,
        isNodeActive,
        type,
        getNodeHref,
        canEdit,
        canDelete,
        filterColumns,
        filters,
        showDefaultColumns,
        baseUrl,
        query: defaultQuery,
        setQ,
        sortBy,
        showImages,
        setSortBy,
        viewType,
        setViewType,
        queryKey
      }}
    >
      <VBox>
        <Typography sx={{ '@media screen and (max-width: 720pt)': { textAlign: 'center'} }} level="h1" component="h1">{heading}</Typography>
        {showToolBar && (
          <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'stretch', '@media screen and (max-width: 720pt)': { marginTop: 0 }, gap: 1 }}>
            {canAdd && <Button
              sx={{ transitionDelay: `100ms` }}
              variant="solid"
              color="primary"
              onClick={() => navigate(addUrl)}
              startDecorator={<Plus />}
            >{i18n.t('add')}</Button>}
            <Box sx={{ '@media screen and (max-width: 720pt)': { display: 'none', flex: 1 },  display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 1 }}>
              {filterChildren}
            </Box>
            <Box sx={{ '@media screen and (max-width: 720pt)': { flex: 1 }, alignItems: 'stretch', justifyContent: 'flex-start' }}>
              {canSelectViewType && <ViewTypeSelect viewType={viewType} onChange={(value) => setViewType(value)} />}
            </Box>
            <Button onClick={() => setShowingFiltersDialog(true)} variant="plain" startDecorator={<Funnel />} sx={{ '@media screen and (min-width: 720pt)': { display: 'none' } }}>{i18n.t('filter')}</Button>
          </Box>
        )}
        <React.Suspense fallback={<Loading />}>
          <GenericListView Icon={Icon} NodeCardComponent={NodeCardComponent} />
        </React.Suspense>
      </VBox>
      <Modal open={showingFiltersDialog} onClose={() => setShowingFiltersDialog(false)}>
        <ModalDialog>
          <ModalClose />
          <VBox>
            {filterChildren}
          </VBox>
        </ModalDialog>
      </Modal>
    </GenericListContext.Provider>
  ) 
}


const renderColumn = ({ type, Icon, node, column }) => {
  const value = node[column.id]
  switch(column.type) {
    case "avatar":
      return ( 
        <Avatar src={value} style={{ aspectRatio: '1/1', height: '32pt'}} />
      )
    case "datetime-local": {
      const date = moment(value)
      let dateLabel = date.format('YYYY-MM-DD HH:mm:ss')
      if (date.diff(moment(), 'day') < 14) {
        dateLabel = date.fromNow()
      }
      return date.isValid() ? (
        <Typography as="a" href="#" title={date.format('YYYY-MM-DD HH:mm:ss')}>{dateLabel}</Typography>
      ) : (
        <Icon size={32} />
      )
    }
    case "date": {
      const date = moment(value)
      let dateLabel = date.format('YYYY-MM-DD')
      //if (date.diff(moment(), 'day') < 14) {
      //  dateLabel = date.fromNow()
      //}
      return date.isValid() ? (
        <Typography as="a" href="#" title={date.format('YYYY-MM-DD')}>{dateLabel}</Typography>
      ) : (
        <Typography>-</Typography>
      )
    }
    case "number":
      if (isNaN(value)) {
        return <span>-</span>
      }
      return ( 
        <Typography sx={{ textAlign: 'right' }}>{value?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Typography>
      )
    case "image":
      return value ? (
        <Img 
          src={value}
          style={{ objectFit: 'contain', width: '50pt' }}
        />
      ) : (
        <Icon size={32} />
      )
    default:
      if (column.render instanceof Function) {
        return column.render({ node, column })
      }
      if (column.link) {
        return <Link to={column.getLinkHref({ node, column })}>{value}</Link>
      }
      if (value instanceof Array) {
        return value.map(n => renderColumn({ Icon, n, column }))
      } else if (value instanceof Object) {
        if (column.link) {
          return (
            <Link to={column.getLinkHref({ node, column })}>{value.name ?? '-'}</Link>
          )
        } else {
          return value?.name ?? '-'
        }
      }
      return value
  }
}


export function GenericListView({
  NodeCardComponent,
  Icon
}) {
  const basePath = useBaseRoute()
  const hostname = useHostname()
  const {
    queryKey,
    query: defaultQuery,
    path,
    empty,
    baseUrl,
    canView,
    getNodeHref,
    isNodeActive,
    isMobile,
    canEdit,
    canDelete,
    canPaginate,
    showDefaultColumns,
    avatar,
    filters,
    showImages,
    q,
    hasMoreMenu,
    type,
    columns,
    setQ,
    sortBy,
    emptyText,
    setSortBy,
    viewType,
    setViewType
  } = useContext(GenericListContext)

  const query = {
    ...defaultQuery,
    q,
    ordering: sortBy,
    ...filters
  }

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, error, isFetching, isRefetching, isPreviousData } = useInfiniteResource({
    queryKey: [...path.split(/\//g), q, sortBy, query],
    path: `${basePath}${path}`,
    query
  })

  console.log("data", data)

  useEffect(() => {
    refetch()
  }, [q])

  const loading = isLoading || isRefetching

  console.log("data.pages", data)

  const nodes = data?.pages?.map(p => p.results).flat(1) ?? []

  const navigate = useNavigate()

  const handleSortClick = (columnId) => {
    if (sortBy?.replace('-', '') === columnId) {
      if (sortBy.startsWith('-')) {
        setSortBy(sortBy.substr(1))
      } else {
        setSortBy('-' + sortBy)
      }
    } else {
      setSortBy(columnId)
    }
  }
  
  if (error) {
    return (
      <VBox sx={{ background: 'rgba(127, 127, 127, .05)', aspectRatio: '16/9', alignItems: 'center', justifyContent: 'center' }}>
        <Warning weight="bold" size={32} />
        <Typography>{i18n.t('list.loading.error.text')}</Typography>
        <Button loading={isRefetching || isFetching} variant="solid" onClick={() => refetch()}>{i18n.t('list.loading.error.retry')}</Button>
      </VBox>
    )
  }

  return (
    <VBox sx={{ '@media screen and (max-width: 720pt)': { overflowY: 'scroll', height: '100vh' },  filter: isPreviousData ? 'saturate(0)' : 'none' }}>
      <SwitchCase value={viewType}>
        <Case match="menu">
          <List>
            <ForEach collection={nodes}>
              <Do>
                {((node, i) => {
                  if (!node) {
                    return null
                  }
                  const href = getNodeHref(node)
                  return (
                    <ListItem
                      key={node.id}
                      component={NavLink}
                      to={href}
                    >
                      <ListItemButton
                        selected={location.pathname.indexOf(href) === 0}
                      >
                        <ListItemDecorator sx={{ p: 0 }}>
                          <Icon weight="bold" size={22} />
                        </ListItemDecorator>
                        <ListItemContent>
                          <Typography level="body-xs" fontWeight={600}>{node.name}</Typography>
                        </ListItemContent> 
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </Do>
            </ForEach>
          </List>
        </Case>
        <Case match="grid">
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            {loading ? (
              <ForEach collection={new Array(4).fill([1, 2, 3, 4])}>
                <Do>
                  {(n, i) => (
                    <Grid item md={3}>
                      <NodeCard sx={{ p: 0, transitionDelay: `${i * 100}ms`}} loading={loading} /> 
                    </Grid>
                  )}
                </Do>
              </ForEach>
            ) : (
              <ForEach collection={nodes}>
                <Do>
                  {(node, i) => {
                    if (!node) {
                      return <></>
                    }
                    return (
                      <Grid item key={node.id} xs={6} md={3}> 
                        <NodeCardComponent sx={{ p: 0, transitionDelay: `${i * 100}ms`}} component={Link} to={`${baseUrl}/${node.id}`} {...node} />
                      </Grid>
                    )
                  }}
                </Do>
                <Empty>
                  <EmptyView text={emptyText} Icon={Icon} />
                </Empty>
              </ForEach>
            )}
          </Grid>
        </Case>
        <Case match="list">
          <Table width="100%" sx={{ width: '100%'}}>
            <thead>
              <tr>
                {showDefaultColumns && (
                  <>
                    <th>
                      
                    </th>
                    <th>
                      <Typography>
                        {i18n.t('name')}
                      </Typography>
                    </th>
                  </>
                )}
                {columns.filter(c => !isMobile || c.sm).map(column => {
                  if (column.sortable) {
                    const active = sortBy?.replace('-', '') === column.id
                    return (
                      <th key={column.id}>
                        <Typography sx={{ opacity: active ?  1 : 0.5, cursor: 'pointer', fontWeight: active ? 900 : 800 }} as="button" onClick={() => handleSortClick(column.id)}>
                          {i18n.t(column.name)} {sortBy?.replace('-', '') === column.id ? (
                            sortBy.startsWith('-') ? (
                              <CaretDown weight="bold" />
                            ) : <CaretUp weight="bold" />
                          ) : null}
                        </Typography>
                      </th>
                    )
                  }
                  return (
                    <th key={column.id}>
                      <Typography sx={{ opacity: 0.5 }}>
                        {i18n.t(column.name)}
                      </Typography>
                    </th>
                  )
                })}
                {hasMoreMenu && <th></th>}
              </tr>
            </thead>
            <tbody>
              {loading ?
                <ForEach collection={new Array(5).fill(5)}>
                  <Do>
                    {((n, i) => (
                      <tr key={`${i}`}>
                        {showDefaultColumns && (
                          <>
                            <td>
                              <Box sx={{ position: 'relative', overflow:'hidden', width: '32pt', aspectRatio: '1/1' }}>
                                <Skeleton loading={loading} />
                              </Box>
                            </td>
                            <td>
                              <Typography
                                sx={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Skeleton loading={loading}>Loading</Skeleton>
                              </Typography>
                            </td>
                          </>
                        )}
                        {columns.filter(c => !isMobile || c.sm).map(column => (
                          <td key={column.id}>
                            <Typography
                              sx={{ position: 'relative', overflow: 'hidden' }}
                            >
                              <Skeleton loading={loading}>Loading</Skeleton>
                            </Typography>
                          </td>
                        ))} 
                        {hasMoreMenu && <td style={{ textAlign: 'right'}}>
                          <Typography
                            sx={{ position: 'relative', overflow: 'hidden' }}
                          >
                            <Skeleton loading={loading}>...</Skeleton>
                          </Typography>
                        </td>}
                      </tr>
                    ))}
                  </Do>
                </ForEach>
              :
                <ForEach collection={nodes}>
                  <Do>
                    {(node, i) => <GenericListRow Icon={Icon} node={node} number={i} />}
                  </Do>
                  <Empty>
                    <tr>
                      <td style={{ padding: 0 }} colSpan={columns.filter(c => !isMobile || c.sm).length + 1}>
                        <EmptyView text={emptyText} Icon={Icon} />
                      </td>
                    </tr>
                  </Empty>
                </ForEach>
              }
            </tbody>
          </Table>
        </Case>
      </SwitchCase>
      {(canPaginate && hasNextPage && nodes?.length > 0) && (
        <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'center' }}>
          <Button loading={isFetchingNextPage} variant="outlined" onClick={() => fetchNextPage()}>{i18n.t('load-more')}</Button>
        </Box>
      )}
    </VBox>
  )
}
