
import { useParams } from 'react-router-dom';
import i18n from '@/i18n';

import { useState } from 'react';
import { TextField } from '@/components/InputField';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { Container, Typography } from '@mui/joy';
import { Button } from '@mui/joy';
import { Check, ExclamationMark, Warning, XCircle } from '@phosphor-icons/react';
import { updateUser } from '@/actions/aloader';
import { useEffect } from 'react';
import { useResource } from '@/hooks';
import { useManager } from '@/hooks';
import { VBox } from '@/components/VBox';
import { CircularProgress } from '@mui/material';
import ErrorPage from '@/pages/error';
import { LoadingPage } from '@/pages/loading';
import { ArtworkField } from '@/components/ArtworkField';

export function EditUserPage() {
    const { userId } = useParams()
  return (
    <Container>
      <EditUserContainer userId={userId} />
    </Container>
  )
}

export function EditUserContainer({
    userId
}) {
    const manager = useManager()
    const [saving, setSaving] = useState(false)
    const [values, setValues] = useState({})
    const { data, isLoading, error, refetch } = useResource({
        queryKey: [manager?.type, manager?.slug, 'user', userId],
        path: `/${manager?.type}/${manager?.slug}/user/${userId}`,
        enabled: !!manager
    })

    useEffect(() => {
        setValues(data)
    }, [data])

    const handleSubmit = event => {
        event.preventDefault()
        setSaving(true)
        updateUser(manager?.type, manager?.slug, userId, values).then(result => {
            setSaving(false)
        }).catch(e => {
            console.error(e)
            setSaving(false)
        })
        return false
    }
     
  const updateValues = data => {
    const newValues = {
      ...values,
      ...data
    }
    setValues(newValues) 
  }

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1>{i18n.t('edit-user')}</h1>
      <img src={values?.image_url} style={{ width: '120pt', aspectRatio: '1/1', borderRadius: '100%'}} />
      <TextField
        type="text"
        name="username"
        label={i18n.t('username')}
        placeholder={i18n.t('enter-username')}
        readOnly
        defaultValue={values?.username}
      />
      <TextField
        type="email"
        name="email"
        label={i18n.t('email')}
        placeholder={i18n.t('enter-email')}
        readOnly
        defaultValue={values?.email}
      />
      {manager?.type !== 'artist' ? (
        <>
          <SelectRelatedNodes
            label={i18n.t('labels')}
            ownerNodeId={null}
            nodeType="label"
            ownerNodeType="user"
            addLabel={"add-labels"}
            allLabel={i18n.t('all-labels')}
            ownLabel={i18n.t('user-labels')}
            nodes={values?.label_users ?? []}
            onChange={(label_users) => updateValues({ label_users })}
          />
          <SelectRelatedNodes
            label={i18n.t('artists')}
            ownerNodeId={null}
            nodeType="artist"
            ownerNodeType="user"
            addLabel={"add-artists"}
            allLabel={i18n.t('all-artists')}
            ownLabel={i18n.t('user-artists')}
            nodes={values?.artist_users ?? []}
            onChange={(artist_users) => updateValues({ artist_users })}
          />
          <SelectRelatedNodes
            label={i18n.t('payees')}
            ownerNodeId={null}
            nodeType="payee"
            ownerNodeType="user"
            addLabel={"add-payees"} 
            allLabel={i18n.t('all-payees')}
            ownLabel={i18n.t('user-payees')}
            nodes={values?.payee_users ?? []}
            onChange={(payee_users) => updateValues({ payee_users })}
          />
          <SelectRelatedNodes
            label={i18n.t('people')}
            ownerNodeId={null}
            nodeType="person"
            ownerNodeType="user"
            addLabel={"add-person"}
            allLabel={i18n.t('all-people')}
            ownLabel={i18n.t('user-people')}
            nodes={values?.person_users ?? []}
            onChange={(person_users) => updateValues({ person_users })}
          />
        </>
      ) : null}
      <Button loading={saving} startDecorator={<Check />} type="submit" variant="solid" color="primary">{i18n.t('save-user')}</Button>
    </form>
  )
}