import { Box, styled } from '@mui/joy';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';


export const BottomTabBar = styled(
  Box,
  {
    slot: 'root',
    name: 'BottomTabBar'
  }
)(({ theme }) => ({
  '@media (min-width: 600px)': {
    display: 'none'
  },
  background: theme.palette.background.default,
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'stretch',
  borderTop: '1pt solid ' + theme.palette.divider,
}))


export function BottomTabBarTabLink({
  children,
  href,
  icon,
  ...props
}) {
  const Icon = icon
  const location = useLocation()
  const isActive = location.pathname.indexOf(href) === 0
  return (
    <Link 
      style={{ 
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        padding: '8px 12pt',
        flex: 1,
        textAlign: 'center', 
      }}
      to={href}
      {...props}
    >
      {icon && <Icon size={38} />}
      {children}
    </Link>
  )
}