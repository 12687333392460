import React, { useState } from 'react' 

import { updateLabel, uploadImageToNode } from '@/actions/aloader'
import { ColorField, TextField, ToggleField } from '@/components/InputField'
import { VBox } from '@/components/VBox'
import { useResource } from '@/hooks'
import { extractColors } from 'extract-colors'
 
import { Link, useParams } from 'react-router-dom'
import i18n from '@/i18n'
import { Button, Container, Typography } from '@mui/joy'

import { ArtworkField } from '@/components/ArtworkField'
import { useEffect } from 'react'

import { Check } from '@phosphor-icons/react'
import { useManager } from '@/hooks'
import { CircularProgress } from '@mui/material'
import LoadingPage from '@/pages/loading'
import ErrorPage from '@/pages/error'
import { useMutation } from '@tanstack/react-query'
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes'
import { useCallback } from 'react'

export default function EditLabelPage() {
  const { labelId } = useParams()
  return ( 
      <EditLabelView labelId={labelId} /> 
  )
}

export function LabelForm({
  values: defaultValues,
  submitLabel,
  distributing,
  onDistribute = (event) => {},
  label,
  saving,
  actions,
  onSubmit = () => console.error('onSubmit not implemented'),
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)
  const [logotype, setLogotype] = useState(null)
  const [loading, setLoading] = useState(false)
  const pickColorFromLogotype = () => {
    if (values?.logotype) {
      const file = URL.createObjectURL(values.logotype)
      extractColors(file, { crossOrigin: true }).then(data => {
        if (values.color != data[0].hex) {
          setValues({ ...values, color: data[0].hex })
        }
      }).catch(e => {
        console.error(e)
      })
    } else if (values?.logotype_url) {
      extractColors(values.logotype_url, { crossOrigin: true }).then(data => {
        if (values.color != data[0].hex) {
          setValues({ ...values, color: data[0].hex })
        }
      }).catch(e => {
        console.error(e)
      })
    }
  }
  useEffect(() => {
    setValues(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    if (!values.style) {
      values.style = {}
    }
    onSubmit(values) 
  }, [values])

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <VBox>
        <TextField
          type="text"
          name="name"
          label={i18n.t('name-of-label')}
          defaultValue={values?.name}
          loading={loading}
          placeholder={i18n.t('name-of-label')}
          required
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <ToggleField
          label={i18n.t('is-branded')}
          checked={values?.is_branded ?? false}
          onChange={(checked) => setValues({ ...values, is_branded: checked ?? false })}
        />
        <ArtworkField
          imageUrl={values?.logotype_url}
          accept=".png,.jpg,.jpeg,.svg"
          onChange={(file) => {
            setValues({ ...values, logotype: file })
          }}
          label={i18n.t('logotype')}
        />
        <ColorField
          label={i18n.t('accent-color')}
          defaultValue={values?.color}
          placeholder={i18n.t('accent-color')}
          name="color"
          required
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => pickColorFromLogotype()}
          >
            {i18n.t('pick-from-logotype')}
          </Button>
        </ColorField>
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          placeholder={i18n.t('add-users')}
          ownerNodeType="label"
          ownerNodeId={label?.id}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('label-s-users')}
          showName={false}
          nodes={values?.label_users ?? []}
          canCreate={true}
          junctionNodeColumns={[
            {
              id: 'role',
              name: i18n.t('role'),
              type: 'belongsTo',
              nodeType: 'role'
            }
          ]}
          createNodeColumns={[
            {
              label: i18n.t('first-name'),
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              label: i18n.t('last-name'),
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              label: i18n.t('email'),
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          columns={[
            {
              id: 'name',
              name: i18n.t('name'),
              type: 'text'
            }
          ]}
          onChange={(label_users) => {
            setValues({ ...values, label_users })
          }}
        />
        <Button type="submit" variant="solid" sx={{ alignSelf: 'flex-start' }} color="primary" startDecorator={<Check />}>{i18n.t('save-label')}</Button>
      </VBox>
    </form>
  )
}

export function EditLabelView({
  labelId,
  managed=true
}) {
  const manager = useManager()
  const [values, setValues] = useState({})
  const [saving, setSaving] = useState(false)
  const { data: label, refetch, isLoading, error } = useResource({
    queryKey: ['label', labelId],
    path: `${managed ? `/${manager?.type}/${manager?.id}` : ''}/label/${labelId}`,
    enabled: !!manager
  }) 
  useEffect(() => {
    setValues(label)
  }, [label])

  const loading = true

  useEffect(() => {
    
  }, [])

  const saveLabel = async (values) => {
    await updateLabel(manager?.type, manager?.slug, values);
    if (values.logotype) {
      await uploadImageToNode({ nodeType: 'label', nodeId: labelId, files: { logotype: values.logotype } });
    }
  };

  const mutation = useMutation({
    mutationFn: saveLabel,
    onSuccess: () => {
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleSubmit = (values) => {
    mutation.mutate(values);
    return false;
  };

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (error || !values) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  return (
    <Container>
      <Link to={`..`}>{i18n.t('back-to-label')}</Link>
      <Typography level="title-lg">{i18n.t('edit-label')}</Typography>
      <LabelForm
        onSubmit={handleSubmit}
        saving={saving}
        label={label}
        values={values}
        submitLabel={i18n.t('save-label')}
      />
    </Container>
  )
}