import React, { useState } from 'react';

import { Button, Table, Modal, ModalDialog, Box, ModalClose, Avatar, List, ListItem, ListItemButton, IconButton, Autocomplete, CircularProgress } from '@mui/joy'

import { InputFieldFrame, InputField } from './InputField';

import i18n from '@/i18n'

import { useEffect } from 'react';

import { Typography } from '@mui/joy';
import { authorizedFetch } from '@/client';
import { useManager } from '@/hooks';

export function SelectRelatedNodeField({
  label,
  errors,
  ...props
}) { 
  return ( 
    <InputField
      label={label}
      errors={errors}
    > 
      <SelectRelatedNode {...props} />
    </InputField> 
  )
}

export function SelectRelatedNode({
  label,
  placeholder,
  multiple=false,
  node,
  onChange,
  errors,
  readOnly,
  nodeType,
  startDecorator
}) {

  const manager = useManager()

  const [selectedNode, setSelectedNode] = useState(node)

  const [loading, setLoading] = useState(false)

  const [q, setQ] = useState('')

  const [suggestedNodes, setSuggestedNodes] = useState([])

  useEffect(() => {
    setSelectedNode(node)
  }, [node])


  useEffect(() => {
    onChange(selectedNode)
  }, [selectedNode])


  const handleChange = (event, value) => {
    if (value) {
      setSelectedNode(value)
    }
  }

  const handleInputChange = event => {
    const value = event.target.value
    setQ(value)
  }

  useEffect(() => {
    setLoading(true)
    if (manager) {
      authorizedFetch("GET", `/${manager?.type}/${manager?.slug}/${nodeType}`, {}, { q }).then(result => {
        const nodes = result.results
        let filteredNodes = [...nodes].filter(n => n.name.indexOf(q) !== -1)
        if (filteredNodes.length > 30) {
          filteredNodes = filteredNodes.slice(0, 30)
        }
        setSuggestedNodes(filteredNodes)
        setLoading(false)
      })
    }
  }, [q])
 
  const handleSelectNode = (node) => {
    setSelectedNode(node)
    setQ('')
  }

  return (
    <> 
        {selectedNode ? (
          <InputFieldFrame sx={{ p: 1, alignItems: 'center' }}>
            <Typography sx={{ flex: 1 }}>
              {(multiple && selectedNode instanceof Array) ? (
                selectedNode?.length > 2 ? i18n.t('multiple-values') : (selectedNode?.length === 1 ? selectedNode[0].name : '')
              ) : selectedNode?.name}
            </Typography>
            {!readOnly && <Button variant="outlined" color="primary" onClick={() => setSelectedNode(null)}>{i18n.t('remove')}</Button>}
          </InputFieldFrame>
        ) : (
          <Autocomplete
            sx={{ flex: 1, p: '8px 12px' }}
            getOptionLabel={option => option.name}
            variant="outlined"
            readOnly={readOnly}
            multiple={multiple}
            placeholder={placeholder}
            getOptionKey={option => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={suggestedNodes}
            onChange={handleChange}
            onInputChange={handleInputChange}
            startDecorator={startDecorator}
            endDecorator={
              loading ? (
                <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
              ) : null
            }
          />
        )}  
    </>
  )
}