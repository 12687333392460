
import i18n from '@/i18n';

import { Chip, Container } from '@mui/joy';
import { CardsThree } from '@phosphor-icons/react';
import { ReleaseCard } from '@/components/ReleaseCard';
import { GenericList } from '@/components/GenericList';
import moment from 'moment';
import { Link } from 'react-router-dom';

export function ReleasesPage() {
  return (
    <ReleasesView />
  )
}

export function ReleasesView() {
  return (
    <Container>
      <GenericList
        showDefaultColumns={false}
        sortByFields={[
          {
            id: '-released',
            name: i18n.t('recent-to-oldest-release-date')
          },
          {
            id: 'name',
            name: i18n.t('alphabetically')
          },
          {
            id: '-edited',
            name: i18n.t('recent-to-oldest-edit')
          },
          {
            id: '-submitted',
            name: i18n.t('recent-to-oldest-submitted')
          },
        ]}
        filterColumns={[
          {
            id: 'status',
            type: 'select',
            placeholder: i18n.t('status'),
            options: [
              {
                id: '',
                name: i18n.t('all-statuses')
              },
              {
                id: 'draft',
                name: i18n.t('draft')
              },
              {
                id: 'submitted',
                name: i18n.t('submitted')
              },
              {
                id: 'released',
                name: i18n.t('released')
              }
            ]
          },
          {
            id: 'label',
            type: 'belongsTo',
            placeholder: i18n.t('label'),
            nodeType: 'label'
          }
        ]}
        columns={[
          {
            id: 'thumbnail_url',
            name: 'image',
            type: 'image',
            sortable: true,
            sm: true,
            render: ({ node }) => node.name
          },
          {
            id: 'name',
            name: 'name',
            sm: true,
            type: 'name',
            sortable: true,
            render: ({ node }) => <Link to={`/dashboard/release/${node.id}`}>{node.name}</Link>
          },
          {
            id: 'released',
            name: 'released',
            type: 'date',
            sortable: true,
            render: ({ node }) => moment(node.released).isValid() ? moment(node.released).format('YYYY-MM-DD') : '-'
          },
          {
            id: 'updated',
            name: 'updated',
            type: 'date',
            sortable: true,
            render: ({ node }) => moment(node.updated).isValid() ? moment(node.released).format('YYYY-MM-DD') : '-'
          },
          {
            id: 'submitted',
            name: 'submitted',
            sortable: true,
            type: 'date'
          },
          {
            id: 'status',
            name: 'status',
            render: ({ node }) => {
              let status = null
              if (node.submitted) {
                status = 'submitted'
                if (moment(node.released).isSameOrBefore(moment())) {
                  status = 'released'
                }
              }
              if (status) {
                return (
                  <Chip color="primary">{i18n.t(status)}</Chip>
                )
              }
              
            }
          },
          {
            id: 'submitted',
            name: 'submitted',
            render: ({ node }) => {
              return moment(node.submitted).isValid() ? moment(node.submitted).format('YYYY-MM-DD')  : '-'
            }
          }/*,
          {
            id: 'pitch',
            name: 'pitch',
            render: ({ node }) => {
              if (moment(release.submitted).isValid() && moment(node.released).isAfter(moment())) {
                return <Button>Pitch</Button>
              }
            }
          }*/
        ]}
        Icon={CardsThree}
        showImages={true}
        path={`/releases`}
        addLabel={i18n.t('add-release')}
        filterPlaceholder={i18n.t('filter-releases')}
        NodeCardComponent={ReleaseCard}
        heading={i18n.t('releases')}
        addUrl={`../release/add`}
        baseUrl={`../release`}
      />
    </Container>
  )
}

export default ReleasesPage