import React, { useCallback, useState } from 'react';
import { Box, Button, Container } from '@mui/joy';
import { InputField, TextField } from '@/components/InputField';
import { Link as JoyLink } from '@mui/joy'

import i18n from '@/i18n'

import { ArtworkField } from '@/components/ArtworkField';

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { useHostname } from '@/contexts';
import { Loading } from '@/components/Loading';
import { useEffect } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Check, Plus } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';

import { useNavigate } from 'react-router-dom';
import { getSpotifyId } from '@/utils';
import { uploadCoverArtToArtist } from '@/actions/login';
import { useManager } from '@/hooks';
import ErrorPage from '@/pages/error';
import LoadingPage from '@/pages/loading';
import { useMutation } from '@tanstack/react-query';
import { updateArtist } from '@/actions/aloader';

export default function EditArtistPage() {
  const { artistId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditArtistView artistId={artistId} />
    </React.Suspense>
  )
}

export function ArtistForm({
  artist,
  loading,
  values: defaultValues = {},
  submitButtonLabel = i18n.t("add-artist"),
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const manager = useManager()
  const [changeValues, setChangeValues] = useState({})
  
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(values)
    return false
  }

  useEffect(() => {
    if (artist) {
      setValues({
        ...artist
      })
    }
  }, [artist])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form key={artist?.id} method="POST" onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <TextField
          name="name"
          required
          defaultValue={artist?.name}
          label={i18n.t('artist-name')}
          placeholder={i18n.t("enter-artist-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        {artist && <ArtworkField
          label={i18n.t('picture')}
          imageUrl={values?.image_url}
          onChange={(value) => setValues({ ...values, image: value })}
        />}
        <TextField
          defaultValue={artist?.spotify_id ? `https://open.spotify.com/artist/${artist.spotify_id}` : ''}
          label={i18n.t('spotify-artist-url')}
          name="spotify_url"
          pattern="^(https:\/\/open.spotify.com\/artist\/([a-zA-Z0-9]+)[23](\?(.*))?"
          placeholder="https://open.spotify.com/artist/id"
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value, spotify_id: getSpotifyId(event.target.value) })}
        />
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          placeholder={i18n.t('add-users')}
          ownerNodeType="artist"
          ownerNodeId={artist?.id}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('artist-s-users')}
          canCreate={true}
          showName={false}
          nodes={values?.artist_users ?? []}
          createNodeColumns={[
            {
              label: i18n.t('first-name'),
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              label: i18n.t('last-name'),
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              label: i18n.t('email'),
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          columns={[
            {
              id: 'name',
              name: i18n.t('name'),
              type: 'text'
            }
          ]}
          onChange={(artist_users) => {
            setValues({ ...values, artist_users })
          }}
        />
        {manager?.type === 'organization' && (
          <div className="fieldset-label" style={{ width: '100%' }}>
            <div className={`field field-${changeValues["label"] && 'changed'}`}>
              <label htmlFor="label">
                {i18n.t('label')}*
              </label>
              <SelectRelatedNodes
                readOnly={values?.locked}
                label={i18n.t('labels')}
                ownerNodeId={null}
                nodeType="label"
                ownerNodeType="artist"
                addLabel={"enter-label-name"}
                placeholder={i18n.t('enter-label-name')}
                allLabel={i18n.t('all-labels')}
                ownLabel={i18n.t('track-s-labels')}
                nodes={values?.artist_labels ?? []}
                onChange={(artist_labels) => setValues({ ...values, artist_labels })}
              />
            </div>
          </div>
        )}
        <Button loading={loading} type="submit" startDecorator={<Plus />} variant="solid">{submitButtonLabel}</Button>
      </Box>
    </form>
  )
}

export function EditArtistView({
  artistId,
  managed=true
}) {
  const hostname = useHostname()
  const navigate = useNavigate()
  const manager = useManager()

  const [saving, setSaving] = useState(false)

  const {
    data: artist,
    loading,
    error,
    isLoading,
    refetch
  } = useResource({
    queryKey: [manager?.type, manager?.slug, 'artist', artistId],
    path: `${managed ? `/${manager?.type}/${manager?.slug}` : ''}/artist/${artistId}`,
    enabled: !!manager
  })


  const mutation = useMutation({
    mutationFn: (values) => updateArtist(manager?.type, manager?.id, artistId, { ...values }).then(artist => {
      if (values.image) {
        return uploadCoverArtToArtist(artistId, values.image).then(() => { 
        })
      }
      return artist
    }),
    onMutate: (values) => {
      setSaving(true)
    },
    onError: (error) => {
      console.error(error)
      setSaving(false)
      setError(error)
    },
    onSuccess: (result) => {
      setSaving(false)
      //navigate(`../../${artistId}`)
    }
  })

  const handleSubmit = (values) => {
    console.log(values)
    const image = values.image
    setSaving(true)
    mutation.mutate({
      ...values,
      image
    })
  }

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (artist) {
    return (
      <Container>
        <JoyLink component={Link}>
          <ArrowLeft />
          <Typography>{artist?.name}</Typography>
        </JoyLink>
        <h1>{i18n.t('edit-artist')}</h1>
        <ArtistForm
          loading={saving}
          artist={artist}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  } else {
    return (
      <LoadingPage />
    )
  }
}