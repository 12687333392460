import React, { useState } from 'react';

import { Button, Table, Modal, ModalDialog, Box, ModalClose, Avatar, List, ListItem, ListItemButton, IconButton, Autocomplete, CircularProgress, Checkbox } from '@mui/joy'

import { InputFieldRoot, InputFieldFrame } from './InputField';

import i18n from '@/i18n'

import { useEffect } from 'react';
import { Typography } from '@mui/joy';
import { authorizedFetch } from '@/client';
import { styled } from '@mui/material';
import { Check, MusicNote } from '@phosphor-icons/react';
import CheckBox from '@mui/icons-material/CheckBox';

const SelectBox = styled(
  Box,
  {
    slot: 'root',
    name: 'SelectBox'
  }
)(({ theme }) => ({
  border: theme.palette.common.black
}))

export function SelectNode({
  label,
  heading,
  allLabel,
  addLabel,
  ownLabel,
  multiple=false,
  placeholder: defaultPlaceholder,
  node,
  onChange,
  nodeType,
  startDecorator,
  ownerNodeType,
  ownerNodeId
}) {

  const [selectedNode, setSelectedNode] = useState(node)

  const [loading, setLoading] = useState(false)

  const [q, setQ] = useState('')

  const [suggestedNodes, setSuggestedNodes] = useState([])
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder)

  useEffect(() => {
    setSelectedNode(node)
  }, [node])

  useEffect(() => {
    if (selectedNode instanceof Array) {
      if (selectedNode?.length > 5) {
        setPlaceholder(i18n.t('x-plus-nodes', { length: 5, nodeType }))
      } else if (selectedNode?.length > 1) {
        setPlaceholder(i18n.t('x-nodes', { length: selectedNode.length, nodeType }))
      } else if (selectedNode?.length > 0) {
        setPlaceholder(selectedNode[0].name)
      } else {
        setPlaceholder(defaultPlaceholder)
      }
    } else if (selectedNode instanceof Object) {
      setPlaceholder(selectedNode.name)
    } else {
      setPlaceholder(defaultPlaceholder)
    }
    onChange(selectedNode)
  }, [selectedNode])

  const handleChange = (event, value) => {
    if (value) {
      setSelectedNode(value)
    }
  }

  const handleInputChange = event => {
    const value = event?.target?.value
    setQ(value)
  }

  useEffect(() => {
    setLoading(true)
    authorizedFetch("GET", `/${nodeType}`, {}, { q }).then(result => {
      const nodes = result.results
      let filteredNodes = [...nodes].filter(n => n.name?.toLowerCase().indexOf(q.toLowerCase()) !== -1)
      if (filteredNodes.length > 30) {
        filteredNodes = filteredNodes.slice(0, 30)
      }
      setSuggestedNodes(filteredNodes)
      setLoading(false)
    })
  }, [q])

  return (
    <>
      <SelectBox
        label={label}
      > 
          <Autocomplete
            sx={{ flex: 1, p: 0, flexDirection: 'row', display: 'flex' }}
            disableCloseOnSelect={multiple}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps} style={{ cursor: 'pointer', padding: 3, display: 'flex', flexDirection: 'row', gap: 10, alignItems: 10}}>
                  {multiple && <Checkbox
                    icon={Check}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />}
                  {option.name}
                </li>
              );
            }}
            renderTags={(tags, getTagProps) => {
              return ''
            }}
            value={node}
            getOptionLabel={option => option.name}
            variant="outlined"
            placeholder={placeholder}
            multiple={multiple}
            getOptionKey={option => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={suggestedNodes}
            onChange={handleChange}
            onInputChange={handleInputChange}
            startDecorator={startDecorator}
            endDecorator={
              loading ? (
                <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
              ) : null
            }
          /> 
      </SelectBox>
    </>
  )
}