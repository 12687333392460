import React from 'react';

import { Route } from 'react-router-dom';

import { Navigate } from 'react-router-dom';
import Layout from './pages/layout';

import dashboardRoutes from './pages/dashboard/routes';
import accountsRoutes from './pages/accounts/routes';
import advanceRoutes from './pages/advance/routes';

import { Outlet } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { createRoutesFromElements } from 'react-router-dom';
import { NotAuthorizedPage } from './pages/dashboard/notauthorized';
import LabelsPage from './pages/labels';
import DashboardLayout from './pages/dashboard/layout';

import { NotFoundPage } from './pages/404';
import { EmbedLayout } from './pages/embed/layout';

export default createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/embed" element={<EmbedLayout />}>
        {advanceRoutes}
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="notauthorized" element={<NotAuthorizedPage />} />
        <Route index element={<Navigate to="accounts/login" replace />} />
        {accountsRoutes}
        <Route path="labels" element={<Outlet />}>
          <Route index element={<LabelsPage />} />
        </Route>
        <Route path="label" element={<DashboardLayout />}>
          <Route path=":labelId" element={<Outlet />}>
            <Route index element={<Navigate replace to="overview" />} />
            {accountsRoutes}
            {dashboardRoutes}
          </Route>
        </Route>
        <Route path="artist" element={<DashboardLayout />}>
          <Route path=":artistId" element={<Outlet />}>
          <Route index element={<Navigate replace to="overview" />} />
            {dashboardRoutes}
          </Route>
        </Route>
        <Route path="label" element={<DashboardLayout />}>
          <Route path=":labelId" element={<Outlet />}>
          <Route index element={<Navigate replace to="overview" />} />
        
            {dashboardRoutes}
          </Route>
        </Route>
        <Route path="organization" element={<DashboardLayout />}>
          <Route path=":organizationId" element={<Outlet />}>
            <Route index element={<Navigate replace to="overview" />} />
            {dashboardRoutes}
          </Route>
        </Route>
      </Route>
    </>
  )
)
