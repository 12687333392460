
import moment from 'moment';

import Box from '@mui/joy/Box';

import { useRole } from '@/contexts';

import { useBaseRoute, useUser } from '@/hooks';

import Sidebar from '@/components/Sidebar';
import Header from '@/components/Header';
import { Books, CardsThree, ChartLineUp, Gear, Tag, Waveform } from '@phosphor-icons/react';

import { createTeleporter } from 'react-teleporter';

import { User } from '@phosphor-icons/react';

import i18n from '@/i18n';
import { SquaresFour, ChartBar, Smiley } from '@phosphor-icons/react';
import { Outlet } from 'react-router-dom';
import { AppHeader } from '@/components/AppHeader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@/actions/login';
import { useSessionContext } from '@/contexts/SessionContext';
import { useManager } from '@/hooks'
import { useLocation } from 'react-router-dom';
import { useIsAdminRoute, useResource } from '@/hooks';
import { useIsFetching } from '@tanstack/react-query';
import { Avatar, LinearProgress } from '@mui/joy';
import { BottomTabBar, BottomTabBarTabLink } from '@/components/BottomTabBar';
import RecordLabel from '@/components/RecordLabel';
import { NavigationTreeView } from '@/components/NavigationTree';

export const TitleBar = createTeleporter()

export function DashboardLayout({ children }) {

  const navigate = useNavigate()
  const location = useLocation()

  const user = useUser()

  const manager = useManager()

  const role = useRole()

  const isFetching = useIsFetching()

  const { setUser } = useSessionContext()

  console.log("children", children)

  const { data: docs, isLoading } = useResource({
    queryKey: ['docs'],
    path: `/docs`
  })

  let menuItems = []

  useEffect(() => {
    getCurrentUser().then((data) => {
      if (data) {
        if (!data.code) {
          setUser(data)
        } else {
          navigate(`/accounts/login`)
        }
      }
    }).catch(e => {
      console.error(e)
      //navigate(`/accounts/login`)
    })
  }, [])

  const handleFavIcon = () => {
    if (manager && manager.is_branded) {
      if (manager.favicon_url) {
        let link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.parentNode.removeChild(link)
          link = null
        }
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        link.href = manager.favicon_url;
      }
      if (manager.name) {
        let titleEl = document.querySelector("title");
        if (!titleEl) {
          titleEl = document.createElement('title');
          document.getElementsByTagName('head')[0].appendChild(titleEl);
        }
        titleEl.innerHTML = manager.name
      }
    }
  }

  useEffect(handleFavIcon, [location])
  useEffect(handleFavIcon, [manager])
  
  if (manager) {
    menuItems = [
      {
        "id": "dashboard",
        "href": "/overview",
        "Icon": SquaresFour,
        "text": i18n.t("dashboard"),
        "helpText": "Dashboard"
      },
      {
        "id": "statistics",
        "Icon": ChartBar,
        "href": "/statistics",
        "text": i18n.t("statistics"),
        "query": {
          "start": moment().subtract(28, 'days').format('YYYY-MM-DD'),
          "end": moment().format('YYYY-MM-DD'),
        }
      },
      (manager?.type !== 'artist') ? {
        "id": "artists",
        "href": "/artists",
        "text": i18n.t('artists'),
        "Icon": Smiley
      } : null,
      {
        "id": "releases",
        "href": "/releases",
        "text": i18n.t('releases'),
        "Icon": CardsThree
      },
      {
        "id": "recordings",
        "href": "/recordings",
        "text": i18n.t('tracks'),
        "Icon": Waveform
      },
      {
        "id": "guides",
        "text": i18n.t('guides'),
        "Icon": Books,
        "href": "/guides",
        "items": docs?.results?.length > 0 ? (
          docs?.results?.map(doc => ({
            id: doc.id,
            text: doc.name,
            icon: Books,
            href: `/guides/${doc.id}`
          }))
        ) : []
      },
      ...(['OWNER', 'ADMIN'].indexOf(role?.role) !== 1 ? ([
        (manager?.type !== 'artist') ? {
          "id": "labels",
          "text": i18n.t('labels'),
          "href": `/labels`,
          "Icon": RecordLabel
        } : null,
        {
          "id": "edit",
          "text": i18n.t('settings'),
          "href": "/edit",
          "Icon": Gear
        },
        {
          "id": "people",
          "text": i18n.t('people'),
          "href": "/people",
          "icon": Smiley
        },
        {
          "id": "payees",
          "text": i18n.t('payees'),
          "href": "/payees",
          "icon": User
        },
        (manager?.type !== 'artist') ? ({
          "id": "users",
          "text": i18n.t('users'),
          "href": "/users",
          "icon": User
        }) : null
      ]) : []),
    ]
  }

  const isAdminRoute = useIsAdminRoute()

  const BASE_URL = useBaseRoute()

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch' }}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Sidebar menuItems={menuItems} />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            overflow: 'auto',
          }}
        >
          {!isAdminRoute && <AppHeader user={user} />}
          <Box sx={{ overflow: 'scroll', '@media screen and (max-width: 728pt)': { overflow: 'hidden'}, flex: 1 }}>
            <Outlet />
          </Box>
          <BottomTabBar> 
            <BottomTabBarTabLink to={`${BASE_URL}/overview`} icon={SquaresFour}>{i18n.t('dashboard')}</BottomTabBarTabLink>
            <BottomTabBarTabLink to={`${BASE_URL}/statistics`} icon={ChartLineUp}>{i18n.t('statistics')}</BottomTabBarTabLink>
            <BottomTabBarTabLink to={`${BASE_URL}/account`} icon={() => <Avatar src={user?.image_url} />}>{i18n.t('account')}</BottomTabBarTabLink>
          </BottomTabBar>
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardLayout;