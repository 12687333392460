import { useParams } from 'react-router-dom'

import { EditOrganizationView } from './organization/[slug]/edit'
import { EditArtistView } from './artist/[artistId]/edit'
import { EditLabelView } from './label/[labelId]/edit'

export default function EditManagerPage() {
  const { artistId, labelId, organizationId } = useParams()
  if (artistId) {
    return <EditArtistView managed={false} artistId={artistId} />
  }
  if (labelId) {
    return <EditLabelView managed={false}  labelId={labelId} />
  }
  if (organizationId) {
    return <EditOrganizationView managed={false} organizationId={organizationId} />
  }
}
