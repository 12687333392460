import React from 'react'

import { ToggleButton } from './ToggleButton'
import { ToggleButtonBox } from './ToggleButtonBox'

import i18n from '@/i18n'
import { useEffect } from 'react'
import { useState, useRef } from 'react'

export function Toggle({
    defaultChecked = false,
    children,
    onLabel,
    onChange,
    offLabel,
    disabled,
    readOnly,
    name,
    ...props
}) {

    const [checked, setChecked] = useState(defaultChecked)
    const checkBoxRef = useRef(null)

    useEffect(() => {
        onChange(checked)
    }, [checked])

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }
    return (
        <>
            <input name={name} onChange={handleChange} ref={checkBoxRef} type="checkbox" style={{ display: 'none' }} defaultChecked={checked} />
            <ToggleButtonBox sx={{ opacity: disabled || readOnly ? 0.5 : 1, pointerEvents: disabled ? 'none': undefined }} {...props}>
                <ToggleButton
                    active={checked}
                    onClick={() => checkBoxRef.current.click()}
                >
                    {onLabel ?? i18n.t('on')}
                </ToggleButton>
                <ToggleButton
                    active={!checked}
                    onClick={() => checkBoxRef.current.click()}
                >
                    {offLabel ?? i18n.t('off')}
                </ToggleButton>
            </ToggleButtonBox>
        </>
    )
}