import React, { useState } from 'react';
import { Box, Button, Container } from '@mui/joy';

import i18n from '@/i18n';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { useHostname } from '@/contexts';
import { Img } from '@/components/Img';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { useManager } from '@/hooks';
import ErrorPage from '@/pages/error';
import LoadingPage from '@/pages/loading';

export function PersonView({ personId }) {
  const manager = useManager()
  const hostname = useHostname()
  const navigate = useNavigate()

  const [values, setValues] = useState({})

  const { data: person, isLoading, error, refetch } = useResource({
    queryKey: [manager?.type, manager?.id, 'person', personId],
    path: `/${manager?.type}/${manager?.id}/person/${personId}`,
    enabled: !!manager
  })

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (person) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <h1>{person.name}</h1>
          <Button
            onClick={() => navigate(`edit`)}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <Img src={person.image_url} style={{ marginTop: 1, width: '120pt' }} />
        </Box>
      </Container>
    )
  } else {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    )
  }
}

export default function PersonPage() {
  const { personId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <PersonView personId={personId} />
    </React.Suspense>
  )
}