import React from 'react'

import i18n from '@/i18n'

import { VBox } from '@/components/VBox'
import { Button, Typography } from '@mui/joy'
import { XCircle } from '@phosphor-icons/react'


export function ErrorPage({
    onTryAgainClick
}) {
    return (
        <VBox sx={{ alignItems: 'center', justifyContent: 'center', aspectRatio: '16/9' }}>
            <XCircle size={128} />
            <Typography level="h1">{i18n.t('error.heading')}</Typography>
            <Typography>{i18n.t('error.text')}</Typography>
            <Button onClick={onTryAgainClick}>{i18n.t('try-again')}</Button>
        </VBox>
    )
}

export default ErrorPage;