import React from 'react';
import { Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';

import { ErrorView } from '@/components/ErrorView';
import { LoadingPage } from '@/components/LoadingPage';
import { useManager } from '@/hooks';

export function PitchPage() {
    const manager = useManager()
    return ( 
        <React.Suspense fallback={<LoadingPage />}>
            {manager && <TypeFormPitch />}
        </React.Suspense>
    )

}

export function TypeFormPitch() {
  const manager = useManager()
    if (!manager) {
        return (
            <ErrorView />
        )
    }
    if (manager?.typeformId) {
        return (
            <Widget id={manager?.typeform_id} style={{ width: '100%', height: '100%' }} />
        )
    } else if (manager.pitch_form_embed_url) {
        return (
            <iframe src={manager.pitch_form_embed_url} style={{ width: '100%', height: '100%' }} />
        )
    } else {
        return (
            <ErrorView />
        )
    }
}

export default PitchPage;