import React from 'react'
import { Avatar, Typography } from '@mui/joy'

import { HBox } from '@/components/HBox'
import { VBox } from '@/components/VBox'

import i18n from '@/i18n'

export function AccountRow({ account }) {
    if (!account) {
        return <>Error</>
    }
    if (account.node.banner_url) {
        return (
            <VBox>
                <img src={account?.node.icon_url ?? account.node.banner_url ?? account.node.logotype_url} style={{ width: '100pt' }} />
                <Typography fontSize="sm" sx={{ opacity: 0.5 }} variant="caption">{i18n.t(account.node.type)} · {i18n.t(account.node.role)}</Typography>
            </VBox>
        )
    }
    return (
        <HBox sx={{ alignItems: 'center', gap: '5pt' }}>
            {account.node.avatar_url && (
                <Avatar src={account.node.avatar_url} />
            )}
            <VBox>
                <Typography fontWeight={500}>{account.node.name}</Typography>
            
                <Typography fontSize="sm" sx={{ opacity: 0.5 }} variant="caption">{i18n.t(account.node.type)} · {i18n.t(account.node.role)}</Typography>
            </VBox>
        </HBox>
    )
}
