
import i18n from '@/i18n';

import { GenericList } from '@/components/GenericList';
import { Smiley } from '@phosphor-icons/react';
import { useManager } from '@/hooks';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';

export function AccountsPage() {
  return <AccountsView />
}

export function AccountsView({
  q = '',
  viewType
}) {
  const manager = useManager()
  
  if (!manager) {
    return <>No label</>
  }

  console.log("label.slug", manager.slug)

  return (
    <GenericList
      path={`/accounts`}
      addLabel={i18n.t('add-user')}
      showDefaultColumns={false}
      filterPlaceholder={i18n.t('filter-users')}
      canSelectViewType={false}
      avatar={true}
      canChangeSortBy={false}
      viewType="list"
      canDelete={false}
      canEdit={true}
      Icon={Smiley}
      canView={false}
      columns={[
        {
          id: "image_url",
          name: i18n.t('image'),
          Icon: Smiley,
          type: "custom",
          render: ({ node }) => node.user.image_url ? <Avatar src={node.user.image_url} /> : <Smiley />
      
        },
        {
          id: 'email',
          name: i18n.t('email'),
          render: ({ node }) => (
            <Link to={`../account/${node.id}`}>
              {node.user.email?.length > 0 ? node.user?.email : <span style={{opacity: 0.4}}>{node?.user?.username}</span>}
            </Link>
          )
        }
      ]}
      heading={i18n.t('users')}
      addUrl={`../user/add`}
    />
  )
}

export default AccountsPage