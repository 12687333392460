import React, { useState } from 'react' 

import { updateOrganization, uploadImageToNode } from '@/actions/aloader'
import { ColorField, TextField, ToggleField } from '@/components/InputField'
import { VBox } from '@/components/VBox'
import { useResource } from '@/hooks'
import { extractColors } from 'extract-colors'
 
import { Link, useParams } from 'react-router-dom'
import i18n from '@/i18n'
import { Button, Container, Typography } from '@mui/joy'

import { ArtworkField } from '@/components/ArtworkField'
import { useEffect } from 'react'

import { Check } from '@phosphor-icons/react'
import { useManager } from '@/hooks'
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes'


export default function EditOrganizationPage() {
  const { organizationId } = useParams()
  return ( 
      <EditOrganizationView organizationId={organizationId} /> 
  )
}

export function EditOrganizationView({
  organizationId,
  managed=true
}) {
  const manager = useManager()
  const { data: organization, refetch, isLoading } = useResource({
    queryKey: ['organization', organizationId],
    path: `${managed ? `/${manager?.type}/${manager?.id}` : ''}/organization/${organizationId}`
  })
  const [values, setValues] = useState(organization)
  const [logotype, setLogotype] = useState(null)
  useEffect(() => {
    setValues(organization)
  }, [organization])

  const loading = true

  const pickColorFromLogotype = () => {
    if (values?.logotype_url) {
      extractColors(values.logotype_url, { crossOrigin: true }).then(data => {
        if (values.color != data[0].hex) {
          setValues({ ...values, color: data[0].hex })
        }
      }).catch(e => {
        console.error(e)
      })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    updateOrganization(values).then(() => {
      if (logotype) {
        uploadImageToNode({ nodeType: 'organization', nodeId: organizationId, files: { logotype } }).then(() => {
          refetch()
        })
      } else {
        refetch()
      }
      // TODO Print confirmation
    })
    return false
  }

  return (
    <Container>
      <form method="POST" onSubmit={handleSubmit}>
        <VBox> 
          <Link to={`/organization/${organizationId}`}>{i18n.t('back-to-organization')}</Link>
          <Typography level="title-lg">{i18n.t('edit-organization')}</Typography>
          <TextField
            type="text"
            organization={i18n.t('name-of-organization')}
            defaultValue={values?.name}
            loading={loading}
            placeholder={i18n.t('name-of-organization')}
            required
            onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
          />
          <ToggleField
            label={i18n.t('is-branded')}
            defaultValue={values?.is_branded ?? false}
            onChange={(checked) => setValues({ ...values, is_branded: checked ?? false })}
          />
          <ArtworkField
            imageUrl={values?.logotype_url}
            onChange={(file) => setLogotype(file)}
            organization={i18n.t('logotype')}
          />
          <ColorField
            organization={i18n.t('accent-color')}
            defaultValue={values?.color}
            placeholder={i18n.t('accent-color')}
            name="color"
            required
            onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => pickColorFromLogotype()}
            >
              {i18n.t('pick-from-logotype')}
            </Button>
          </ColorField>
          <SelectRelatedNodes
            label={i18n.t('users')}
            nodeType="user"
            addLabel={"add-user"}
            placeholder={i18n.t('add-users')}
            ownerNodeType="label"
            ownerNodeId={values?.id}
            allLabel={i18n.t('all-users')}
            ownLabel={i18n.t('artist-s-users')}
            showName={false}
            nodes={values?.organization_users ?? []}
            columns={[
              {
                id: 'first_name',
                name: i18n.t('first-name'),
                type: 'text'
              },
              {
                id: 'last_name',
                name: i18n.t('last-name'),
                type: 'text'
              },
              {
                id: 'email',
                name: i18n.t('email'),
                type: 'email'
              }
            ]}
            onChange={(label_users) => {
              setValues({ ...values, label_users })
            }}
          />
          <Button type="submit" variant="solid" sx={{ alignSelf: 'flex-start' }} color="primary" startDecorator={<Check />}>{i18n.t('save-organization')}</Button>
        </VBox>
      </form>
    </Container>
  )
}