import React, { useState, useEffect } from 'react';

import { Container } from '@mui/joy';
import { Button } from '@mui/joy';
import { Check } from '@phosphor-icons/react';
import { updateAccount } from '@/actions/aloader';
import { useParams } from 'react-router-dom';
import i18n from '@/i18n';

import { InputField, TextField } from '@/components/InputField';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';

import { useSuspenseResource } from '@/hooks';
import { useManager } from '@/hooks';
import LoadingPage from '@/pages/loading';

export function EditAccountPage() {
    const { accountId } = useParams()
  return (
    <Container>
      <h1>{i18n.t('edit-account')}</h1>
      <React.Suspense fallback={<LoadingPage />}>
        <EditAccountContainer accountId={accountId} />
      </React.Suspense>
    </Container>
  )
}

export function EditAccountContainer({
    accountId
}) {
    const manager = useManager()
    const [saving, setSaving] = useState(false)
    const [values, setValues] = useState({})
    const { data } = useSuspenseResource({
        queryKey: [manager?.type, manager?.slug, 'account', accountId],
        path: `/${manager?.type}/${manager?.slug}/account/${accountId}`,
        enabled: !!manager
    })

    useEffect(() => {
        setValues(data)
    }, [data])

    const handleSubmit = event => {
        event.preventDefault()
        setSaving(true)
        updateAccount({ ...values, id: accountId }).then(result => {
            setSaving(false)
        }).catch(e => {
            setSaving(false)
        })
        return false
    }
     
  const updateValues = data => {
    const newValues = {
      ...values,
      ...data
    }
    setValues(newValues) 
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label={i18n.t('email')}
        placeholder={i18n.t('enter-email')}
        readOnly
        defaultValue={values?.user?.email}
      />
      <InputField
        label={i18n.t('role')}
      >
        <select defaultValue={values?.role} style={{ padding: 10, width: '100%'}} name="role" onChange={(event) => updateValues({ role: event.target.value})}>
          <option value="artist" selected>{i18n.t('artist')}</option>
          <option value="admin">{i18n.t('admin')}</option>
        </select>
      </InputField>
      <Button loading={saving} startDecorator={<Check />} type="submit" variant="solid" color="primary">{i18n.t('save-account')}</Button>
    </form>
  )
}