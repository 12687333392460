import Color from "color";
import moment from "moment";
import dayjs from 'dayjs';
import { intervalToDuration } from "date-fns";
// From https://github.com/gorangajic/react-svg-morph/issues/34
import parse from "html-react-parser";
import { renderToString } from "react-dom/server";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const parseMoodifyUrlToUri = (str) => {
  let url = new URL(str);
  let uri = `${process.env.NEXT_PUBLIC_URI_SCHEME}:${url.pathname
    .substr(1)
    .split(/\//g)
    .join(":")}`;
  return uri;
};

export const getNodeUri = (node) => {
  if (!node) {
    throw new Error("Node is undefined");
  }
  if (!node.type) {
    throw new Error("Node type of " + JSON.stringify(node) + " is undefined");
  }
  if (node.uri?.indexOf("aloaded:") === 0) {
    return node.uri;
  } else {
    return node?.uri ?? `aloaded:${node.type}:${node.id}`;
  }
};

export const convertMoodifyUriToUrl = (str) => {
  const baseUrl = getBaseUrl();
  const parts = str.split(/:/g).slice(1);
  const url = `${baseUrl}/${parts.join("/")}`;
  return url;
};

export const convertMoodifyUrlToUri = (str) => {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}/dashboard`;
  const parts = str.substr(url.length + 1).split(/\//g);
  const uri = `aloaded:leads:${parts.join(":")}`;
  return uri;
};

export const getBaseUrl = () => {
  let hostname = "localhost";
  let protocol = "http:";
  if (window) {
    if (window.location.hostname) {
      hostname = window.location.hostname;
    }
    if (window.location.port !== "80") {
      hostname += ":" + window.location.port;
    }
  }
  return `${protocol}//${hostname}`;
};

export const formatTime = (time) => {
  const time2 = moment(time);
  const now = moment();
  const days = now.diff(time2, "days");
  if (days < 16) {
    return time2.fromNow();
  } else {
    return time2.format("YYYY-MM-DD");
  }
};

export const setUIColorAccent = (htmlColor) => {
  if (window && document) {
    const color = Color(htmlColor).hsl().object();
    const hue = color.h;
    const saturation = color.s * 0.4;
    const luminance = color.l * 1.6;
    document.documentElement.style.setProperty("--primary-h", hue + "deg");
    document.documentElement.style.setProperty("--primary-s", saturation + "%");
    document.documentElement.style.setProperty("--primary-l", luminance + "%");
  }
};

export const formatMicroPopularity = (number) => {
  let output = "";
  let strNumber = number.toLocaleString(undefined, {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  });
  let isOnDecimal = false;
  let decimalNumber = 0;
  for (let i = 0; i < strNumber.length; i++) {
    let char = strNumber[i];

    if (char === "." || char === ",") {
      isOnDecimal = true;
    } else {
      if (isOnDecimal) {
        if (decimalNumber % 3 === 0) {
          output += " ";
        }
      }
    }
    output += char;
    if (isOnDecimal) {
      decimalNumber += 1;
    }
  }
  return output;
};

export function formatDuration(seconds) {
  try {
    // From https://stackoverflow.com/questions/48776140/format-a-duration-from-seconds-using-date-fns
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const zeroPad = (num) => String(num).padStart(2, "0");

    return [duration.hours, duration.minutes, duration.seconds]
      .filter(Boolean)
      .map(zeroPad)
      .join(":");
  } catch (e) {
    return "-";
  }
}

export function getBaesUrl() {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port !== 80 ? `:${window.location.port}` : ""
  }`;
}

export function durationize(_duration, type = "text") {
  let days = 0;
  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let duration = moment.duration(_duration, "seconds");
  days = duration.days();
  seconds = duration.seconds();
  minutes = duration.minutes();
  hours = duration.hours();
  let str = "";
  if (type === "text") {
    if (days > 0) {
      str += days + " day";
    }
    if (hours > 0) {
      str += " ";
      str += hours + " hour";
    }
    if (minutes > 0) {
      str += " ";
      str += minutes + " min";
    }
    if (minutes < 1 && seconds > 0) {
      str += " ";
      str += seconds + " s";
    }
    if (str?.length < 1) {
      str = "0 s";
    }
  } else {
    str = seconds;
    str = minutes + ":" + str;
    if (hours > 0) {
      str = hours + ":" + str;
    }
  }
  return str;
}

// From https://github.com/gorangajic/react-svg-morph/issues/34

export const getSVGElement = (content, key) => {
  return parse(renderToString(content).replace("<svg", `<svg key="${key}"`));
};

// https://github.com/vercel/next.js/discussions/46300
export function useTransitionWithCompletion() {
  const [isPending, startTransition] = useTransition();

  const pendingPromise = useRef();

  useLayoutEffect(() => {
    if (!isPending && pendingPromise.current) {
      pendingPromise.current.resolve();
      pendingPromise.current = undefined;
    }
  }, [isPending]);

  useLayoutEffect(
    () => () => {
      if (pendingPromise.current) {
        pendingPromise.current.reject();
        pendingPromise.current = undefined;
      }
    },
    []
  );

  const startTransitionWithCompletion = (transition) => {
    const promise = new Promise((resolve, reject) => {
      pendingPromise.current = { resolve, reject };
    });

    startTransition(transition);

    return promise;
  };

  return [isPending, startTransitionWithCompletion];
}

export function getAPIUrl() {
  let protocol = 'http'
  let port = '80'
  let hostname = 'localhost'
  if (typeof window !== 'undefined') {
    protocol = window.location.protocol
    port = window.location.port
    hostname = window.location.hostname
  }
  const api_url = protocol + '//api.' + hostname + (port != 80 ? (':' + ((port) == 3000 ? 8080 : port)) : '')
  return api_url
}

export function getAccountUrl() {
  let protocol = 'http'
  let port = '80'
  let hostname = 'localhost'
  if (typeof window !== 'undefined') {
    protocol = window.location.protocol
    port = window.location.port
    hostname = window.location.hostname
  }
  const api_url = protocol + '//accounts.' + hostname + (port != 80 ? (':' + ((port) == 3000 ? 8080 : port)) : '')
  return api_url
}

export function parseSpotifyUri(value) {
  if (value?.indexOf('spotify:') === 0) {
    const url = value?.split(/\?/g)[0]
    let [protocol,type,id] = url?.split(/:/g)
    return {
      protocol,
      type,
      id
    }
  } else if (value?.indexOf('https://open.spotify.com/') === 0) {
    const url = value?.split(/\?/g)[0]
    let [protocol,,hostname,type,id] = url?.split(/\//g)
    return {
      type,
      hostname,
      protocol,
      id
    }
  }
  return null
}


export function parseSlugs(slugs)  {
  try {
    const parts = (slugs ?? [])?.split(',')
    if (parts.length > 0 && parts[0] === '') {
      return []
    }
    return parts
  } catch (e) {
    return []
  }
}

export function prettyDate(date, fromNow = false) {
  //moment.locale(getLang())
  let momentDate = dayjs(date)
  if (!momentDate.isValid()) {
    return '-'
  }
  if (fromNow && momentDate.diff(dayjs(), 'days') > 14) {
    return momentDate.format('YYYY-MM-DD')
  } else {
    return momentDate.format('YYYY-MM-DD')
  }
}

// From https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function makeId(length=23) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
  }
  return result;
}

export const parseQueryArray = (value) => {
  if (Array.isArray(value)) {
    return value
  }
  if (value) {
    return [value]
  }
  return []
}

export function openSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function isSubject(nodeType) {
  return new RegExp("(.*)(st|er|ee|or|on)$").test(nodeType)
}

export function getSpotifyId(url) {
  return url.split(/\?/)[0].split(/\//g).at(-1);
}

export function isValidISRC(value) {
  return /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/.test(value);
}
