import { Skeleton } from '@/components/Skeleton';

import i18n from '@/i18n';
import { Container } from '@mui/joy';
import { GenericList } from '@/components/GenericList';
import { ArtistCard } from '@/components/ArtistCard';

export function PayeesPage() {
  return (
    <Container>
      <GenericList
        path={`/payees`}
        addLabel={i18n.t('add-payee')}
        canAdd={true}
        filterPlaceholder={i18n.t('filter-payees')}
        NodeCardComponent={ArtistCard}
        avatar={true}
        heading={i18n.t('payees')}
        addUrl={`../payee/add`}
        baseUrl={`../payee`}
      />
    </Container>
  )
}

export function PayeesSkeleton() {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
        <Skeleton style={{ width: '100pt', height: '100pt', aspectRatio: '1/1' }} />
      </div>
    </Container>
  )
}

export default PayeesPage