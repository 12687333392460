import React from 'react';
import { Container } from '@mui/joy';

import i18n from '@/i18n';
import { useState } from 'react';

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { db, collection, addDoc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { PayeeForm } from './[payeeId]/edit';
import { useManager } from '@/hooks';

export default function AddPayeePage() {
  const { payeeId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <AddPayeeView payeeId={payeeId} />
    </React.Suspense>
  )
}

export function AddPayeeView() {
  const manager = useManager()
  const [values, setValues] = useState({})

  const navigate = useNavigate()

  const handleSubmit = (values) => {
    const payee = addDoc(collection(db, 'payees'), values).then((payee) => {
      console.log(payee)
      navigate(`/${manager.type}/${manager.slug}/payee/${payee.id}`)
    }).catch(e => {
      console.error(e)
    })
  }

  return (
    <Container>
      <h1>{i18n.t('add-payee')}</h1>
      <PayeeForm
        onSubmit={handleSubmit}
        payee={null}
        values={{}}
      />
    </Container>
  )
}