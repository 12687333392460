import React from 'react';

import i18n from '@/i18n';

import { RecordingCard } from '@/components/RecordingCard';

import { GenericList } from '@/components/GenericList';
import { MusicNote, Waveform, XCircle } from '@phosphor-icons/react';
import { Container } from '@mui/joy';
import { Link } from 'react-router-dom'
import { PlayButton } from '@/components/PlayButton';

export function RecordingsPage() {
  return <RecordingsView />
}

export function RecordingsView({
  q = '',
  viewType
}) {
  return (
    <Container>
      <GenericList
        path={`/recordings`}
        showDefaultColumns={false}
        addLabel={i18n.t('add-track')}
        filterPlaceholder={i18n.t('filter-tracks')}
        canDelete={false}
        canEdit={true}
        canView={true}
        NodeCardComponent={RecordingCard}
        Icon={MusicNote}
        canSelectViewType={false}
        showImages={true}
        canChangeSortBy={true}
        sortByFields={[
          {
            id: '-created',
            name: i18n.t('recent-to-oldest-created')
          },
          {
            id: 'name',
            name: i18n.t('alphabetically')
          },
          {
            id: '-released',
            name: i18n.t('recent-to-oldest-released')
          }
        ]}
        /*filterColumns={[
          {
            id: 'status',
            type: 'select',
            placeholder: i18n.t('status'),
            options: [
              {
                id: '',
                name: i18n.t('all-statuses')
              },
              {
                id: 'draft',
                name: i18n.t('draft')
              },
              {
                id: 'submitted',
                name: i18n.t('submitted')
              },
              {
                id: 'released',
                name: i18n.t('released')
              }
            ]
          }
        ]}*/
        viewType="list"
        columns={[
          {
            id: 'has_audio',
            name: 'audio',
            render: ({ column, node }) => {
              return (
                node[column.id] ? <><Waveform color="#00aa00" size={28} /></> : <XCircle color="#aa0000" size={28} />
              )
            }
          },
          {
            id: 'player',
            name: 'player',
            type: 'custom',
            render: ({ column, node }) => {
              if (node.audio_url) {
                return (
                  <PlayButton recording={node} />
                )
              }
            }
          },
          {
            id: 'thumbnail_url',
            sortable: false,
            type: 'image',
            name: i18n.t('image'),
          },
          {
            id: 'name',
            name: i18n.t('name'),
            link: true,
            getLinkHref: ({ node, column }) => `../recording/${node.id}`
          },
          {
            id: 'version',
            name: i18n.t('version'),
            type: 'text'
          },
          {
            id: 'isrc',
            name: 'isrc',
            render: ({ node }) => node.isrc
          }
        ]}
        heading={i18n.t('tracks')}
        addUrl={`../recording/add`}
        baseUrl={`recording`}
      />
    </Container>
  )
}

export default RecordingsPage