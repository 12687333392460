import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n';

import { Loading } from '@/components/Loading';


import { useParams } from 'react-router-dom';
import { ArrowLeft, Check } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useResource } from '@/hooks';

import { db, doc, setDoc } from '@/client';
import { useManager } from '@/hooks';
import ErrorPage from '@/pages/error';
import LoadingPage from '@/pages/loading';
import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';
import { updatePayee, uploadImageToNode } from '@/actions/aloader';
import { useMutation } from '@tanstack/react-query';
import { ArtworkField } from '@/components/ArtworkField';
import { useCallback } from 'react';

export default function EditPayeePage() {
  const { payeeId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditPayeeView payeeId={payeeId} />
    </React.Suspense>
  )
}

export function PayeeForm({
  payee,
  values: defaultValues = {},
  onSubmit,
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    onSubmit(values)
    return false
  }, [values])

  useEffect(() => {
    if (payee) {
      setValues({
        ...payee
      })
    }
  }, [payee])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box>
        <TextField
          name="name"
          required
          readOnly={true}
          defaultValue={payee?.name}
          label={i18n.t('payee-name')}
          placeholder={i18n.t("enter-payee-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <TextField
          name="email"
          type="email"
          required
          info={i18n.t('payee-email-info')}
          defaultValue={payee?.email}
          label={i18n.t('payee-email')}
          readOnly={true}
          placeholder={i18n.t("enter-payee-email")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <ArtworkField
          name="image"
          imageUrl={values?.image_url}
          label={i18n.t('avatar')}
          onChange={(value) => {
            setValues({ ...values, image: value })}
          }
        />
        <SelectRelatedNodes
          label={i18n.t('users')}
          nodeType="user"
          addLabel={"add-user"}
          placeholder={i18n.t('add-users')}
          ownerNodeType="payee"
          ownerNodeId={values?.id}
          nodes={values?.payee_users ?? []}
          allLabel={i18n.t('all-users')}
          ownLabel={i18n.t('payee-s-users')}
          showName={false}
          createNodeColumns={[
            {
              label: i18n.t('first-name'),
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              label: i18n.t('last-name'),
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              label: i18n.t('email'),
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          junctionNodeColumns={[
            {
              id: "role",
              name: i18n.t("role"),
              type: "belongsTo",
              nodeType: "role"
            }
          ]}
          columns={[
            {
              id: 'first_name',
              name: i18n.t('first-name'),
              type: 'text'
            },
            {
              id: 'last_name',
              name: i18n.t('last-name'),
              type: 'text'
            },
            {
              id: 'email',
              name: i18n.t('email'),
              type: 'email'
            }
          ]}
          onChange={(payee_users) => setValues({ ...values, payee_users })}
        />
        <Button type="submit" startDecorator={<Check />} variant="solid">{i18n.t('save-payee')}</Button>
      </Box>
    </form>
  )
}

export function EditPayeeView({
  payeeId
}) {
  const manager = useManager()
  const navigate = useNavigate()

  const {
    data: payee,
    loading,
    error,
    isLoading,
    refetch
  } = useResource({
    queryKey: [manager?.type, manager?.slug, 'payee'],
    path: `/payee/${payeeId}`,
    enabled: !!manager
  })

  if (loading) {
    return <Loading />
  }

  const savePayee = async (values) => {
    const result = await updatePayee(manager?.type, manager?.id, payeeId, values)
    if (values.image) {
      const image = await uploadImageToNode({
        nodeType: 'payee',
        nodeId: payeeId,
        files: { image: values.image }
      })
    }   
    return result
  }

  const mutation = useMutation({
    mutationFn: (values) => savePayee({
      ...values
    }),
    onError: (error) => {
      console.error(error)
    },
    onSuccess: (result) => {
      console.log('onSuccess', result)
      //navigate(`../`)
    },
    onSettled: (result) => {
    }
  })

  const handleSubmit = (values) => {
    console.log(values)
    mutation.mutate(values)
  }

  if (error) {
    return (
      <ErrorPage onTryAgainClick={() => refetch()} />
    ) 
  }

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  if (payee) {
    return (
      <Container>
        <HBox component={Link}>
          <ArrowLeft />
          <Typography>{payee?.name}</Typography>
        </HBox>
        <PayeeForm
          payee={payee}
          onSubmit={handleSubmit}
        />
      </Container>
    )
  } else {
    return (
      <LoadingPage />
    )
  }
}